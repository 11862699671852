import { useDispatch, useSelector } from "react-redux";
import { withErrorHandling } from "../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import { logError } from "../../../shared/logging";
import { api } from "../../api/client";
import { InboxCriteria, InboxUiState } from "../../contexts/InboxStateContext";
import { loadInboxStructure } from "../../store/state/inboxStructure/thunks";
import { MessageActions } from "../../store/state/messages/actions";
import { SearchCriteria } from "../../store/state/messages/types";
import { impersonationSelector } from "../../store/state/user/selectors";
import { AppDispatch } from "../../store/store";
import { getAuthMode } from "../../variables";
import { useLocalization } from "../useLocalization";

export const buildSearchCriteria = (criteria: InboxCriteria, requestTotal: boolean) => {
  const search = {
    paging: {
      page: criteria.page,
      size: criteria.size,
      totals: requestTotal,
    },
  } as SearchCriteria;

  search.isImportant = criteria.isImportant;
  search.investors = criteria.investors;
  search.entities =
    criteria.entities === undefined
      ? undefined
      : [...criteria.entities, ...(criteria.funds ?? []).map((fundName) => ({ caption: fundName }))];
  search.categories = criteria.categories;
  search.from = criteria.from;
  search.to = criteria.to;
  search.query = criteria.query;

  return search;
};

const searchMessages = withErrorHandling(api.messages.search);

export const useSearchMessages = () => {
  const dispatch = useDispatch();
  const { sendNotificationError } = useNotificationContext();
  const locale = useLocalization();

  const searchMessage = async (
    requestBody: SearchCriteria,
    appendMessages: boolean,
    updateUi: (value: Partial<InboxUiState>) => void
  ) => {
    const [messagesResponse, error] = await searchMessages(requestBody);
    if (error) {
      logError(error, "searchMessages");
      sendNotificationError(locale.inbox.data_loading_error);
      return;
    }

    if (requestBody.paging.totals === true) {
      updateUi({ total: messagesResponse.total });
    }

    const messages = messagesResponse.items || [];
    if (appendMessages) {
      dispatch(MessageActions.MessagesPreLoaded({ messages }));
    } else {
      dispatch(MessageActions.MessagesLoaded({ messages }));
    }
  };

  return { searchMessage };
};

export const useLoadMessageDetails = () => {
  const dispatch: AppDispatch = useDispatch();
  const isImpersonation = useSelector(impersonationSelector);

  const loadMessageDetails = async (id: string) => {
    const response = await api.messages.getDetails(id);
    if (response.data) {
      dispatch(MessageActions.MessageDetailsLoaded(response.data));
      if (!response.data.isRead && !isImpersonation) {
        const markAsReadResp = await api.messages.markAsRead(id);
        if (markAsReadResp?.success) {
          dispatch(MessageActions.MarkAsRead(id));
          dispatch(loadInboxStructure());
        }
      }
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(response.error?.message ?? "Failed to load message details"));
    }
  };

  return { loadMessageDetails };
};

const markManyAsRead = withErrorHandling(api.messages.markManyAsRead);

export const markMessageAsRead = (msgIds: string | string[]) => {
  return async (dispatch: AppDispatch) => {
    if (getAuthMode() === "impersonation") {
      return Promise.resolve();
    }

    const [data, error] = await markManyAsRead(Array.isArray(msgIds) ? msgIds : [msgIds], true);
    if (error) {
      logError(error, "markManyAsRead");
      return;
    }

    data.updates.forEach((msg) => {
      if (msg.markAsRead) {
        dispatch(MessageActions.MarkAsRead(msg.messageId));
      }
    });
    dispatch(loadInboxStructure());
  };
};
