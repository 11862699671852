import { Box, BoxProps } from "@mui/material";
import useLazyRenderList from "../hooks/useLazyRenderList";

interface Props extends BoxProps {
  chunkSize?: number;
}

const BoxWithLazyRender = ({ chunkSize, children, ...boxProps }: Props) => {
  const { loadMore, loadedItems } = useLazyRenderList({ children, chunkSize });

  return (
    <Box role="listbox" onScroll={loadMore} {...boxProps}>
      {loadedItems}
    </Box>
  );
};

export default BoxWithLazyRender;
