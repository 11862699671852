import { Button, Stack, Typography } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useState } from "react";
import { useSelector } from "react-redux";
import DateRangeSelect from "../../../../../shared/components/DateRangeSelect";
import { useLocalization } from "../../../../hooks/useLocalization";
import { financialFundsSelector, financialInvestorsSelector } from "../../../../store/state/user/selectors";
import FundSelectFilter from "../../../common/filters/FundSelectFilter";
import InvestorSelectFilter from "../../../common/filters/InvestorSelectFilter";
import PaymentStatusFilter from "../../../common/filters/PaymentStatusFilter";
import SwipeableFiltersDrawer from "../../common/SwipeableFiltersDrawer";
import {
  allPaymentStatusCaptions,
  cashFlowFilterDateRanges,
  CashFlowFiltersState,
  getInitialCashFlowFiltersState,
} from "../cashFlowPageState";

interface Props {
  open: boolean;
  onClose: (newFilters: CashFlowFiltersState) => void;
  disabled?: boolean;
}

const CashFlowFiltersMobile = ({ open, onClose, disabled }: Props) => {
  const locale = useLocalization();

  const investors = useSelector(financialInvestorsSelector("CASH FLOWS"));
  const funds = useSelector(financialFundsSelector("CASH FLOWS"));

  const [state, setState] = useState(getInitialCashFlowFiltersState());

  const handleChange = (update: Partial<CashFlowFiltersState>) => {
    setState((prev) => ({ ...prev, ...update }));
  };

  const handleClearFilters = () => {
    handleChange(getInitialCashFlowFiltersState());
  };

  const filtersApplied = !deepEqual(state, getInitialCashFlowFiltersState());

  return (
    <SwipeableFiltersDrawer open={open} onClose={() => onClose(state)}>
      <Stack py={2} px={2}>
        <Stack my={2} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{locale.dashboard.cashFlows.filter_title}</Typography>
          {filtersApplied && (
            <Button variant="text" disabled={disabled} onClick={handleClearFilters}>
              {locale.dashboard.cashFlows.actions.clear_all}
            </Button>
          )}
        </Stack>
        <Stack width="100%" spacing={2}>
          <InvestorSelectFilter
            investors={investors}
            disabled={disabled}
            selectedValues={state.selectedInvestors}
            onSelected={(selectedInvestors) => handleChange({ selectedInvestors })}
          />
          <FundSelectFilter
            funds={funds}
            disabled={disabled}
            selectedValues={state.selectedFunds}
            onSelected={(selectedFunds) => handleChange({ selectedFunds })}
          />
          <DateRangeSelect
            disabled={disabled}
            dateRange={state.selectedDateRange}
            onChange={(selectedDateRange) => handleChange({ selectedDateRange })}
            predefinedRanges={cashFlowFilterDateRanges}
            fullWidth
          />
          <PaymentStatusFilter
            disabled={disabled}
            selectedValues={state.selectedStatuses}
            onSelected={(selectedStatuses) => handleChange({ selectedStatuses })}
            values={allPaymentStatusCaptions}
          />
        </Stack>
      </Stack>
    </SwipeableFiltersDrawer>
  );
};

export default CashFlowFiltersMobile;
