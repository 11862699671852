import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectDashboardLoading = (state: RootState) => state.dashboard.balanceLoading;

export const selectDashboardError = (state: RootState) => state.dashboard.balanceError;

export const selectDashboardBalance = (state: RootState) => state.dashboard.balanceData;

export const selectDashboardBalanceInitialData = (state: RootState) => state.dashboard.balanceInitialData;

export const selectDashboardBalanceFilters = (state: RootState) => state.dashboard.balanceFilters;

export const selectDashboardBalanceFiltersApplied = createSelector(
  [selectDashboardBalanceFilters],
  (filters) => filters.investors.length || filters.funds.length || filters.reportingDateIso !== filters.initialDateIso
);

export const selectCurrentBalanceClient = (state: RootState) => state.dashboard.balanceClient;
