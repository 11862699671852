import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useState } from "react";
import { ImpersonationContactGroup } from "../../api/types/impersonationTypes";
import ImpersonationContactLine from "./ImpersonationContactLine";

interface Props {
  contactGroup: ImpersonationContactGroup;
  filterText: string;
  contactEmail: string;
  onContactViewClick: (email: string) => void;
  showFundName?: boolean;
}

const ImpersonationOptionsSection = ({
  contactGroup,
  filterText,
  contactEmail,
  onContactViewClick,
  showFundName,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>();

  return (
    <Accordion
      onChange={(_, newExpanded) => setIsExpanded(newExpanded)}
      expanded={isExpanded !== undefined ? isExpanded : filterText.trim() !== ""}
      disableGutters
      elevation={0}
      square
      sx={(t) => ({
        backgroundColor: t.palette.background.grey,
        border: `1px solid ${t.palette.divider}`,
        "&:not(:last-child)": {
          borderBottom: 0,
        },
        "&:before": {
          display: "none",
        },
      })}
    >
      <AccordionSummary
        expandIcon={<ChevronRightIcon />}
        sx={(theme) => ({
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
          "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(2),
          },
        })}
      >
        <Typography variant="subtitle2">
          {showFundName && contactGroup.fundName
            ? `${contactGroup.title} / ${contactGroup.fundName}`
            : contactGroup.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {contactGroup.contacts.map((contact) => (
          <ImpersonationContactLine
            key={contact.email}
            contact={contact}
            contactGroup={contactGroup}
            filterText={filterText}
            contactEmail={contactEmail}
            onContactViewClick={onContactViewClick}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ImpersonationOptionsSection;
