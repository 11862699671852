import { Stack } from "@mui/material";
import { useInboxStateContext } from "../../../contexts/InboxStateContext";
import PageScrollerComponent from "../messagesList/desktop/pageScroller/PageScrollerComponent";

const InboxConfigControl = () => {
  const { ui, criteria, updateCriteria, setSelection } = useInboxStateContext();

  const changePage = (delta: number) => {
    const newPage = Math.max(criteria.page + delta, 0);
    setSelection([]);
    updateCriteria({ page: newPage });
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      direction="row"
      sx={{ marginLeft: "auto", height: "2rem" }}
    >
      <PageScrollerComponent
        pageSize={criteria.size || 0}
        currentPage={criteria.page || 0}
        records={ui.total || 0}
        navigateBack={() => changePage(-1)}
        navigateForward={() => changePage(+1)}
      />
    </Stack>
  );
};

export default InboxConfigControl;
