import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Input, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import LoaderBox from "../../../../../shared/components/LoaderBox";
import UserAvatar from "../../../../../shared/components/UserAvatar";
import { logError } from "../../../../../shared/logging";
import { api } from "../../../../api/client";
import { useClientContext } from "../../../../contexts/ClientContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import { UserActions } from "../../../../store/state/user/actions";
import {
  identityAvatarImageSelector,
  identityLargeImageSelector,
  impersonationSelector,
  loadingIdentitySelector,
} from "../../../../store/state/user/selectors";
import { loadLargeAvatar } from "../../../../store/state/user/thunks";
import { AppDispatch } from "../../../../store/store";

const maxAvatarSizeBytes = 1 * 1024 * 1024;

const updateIdentity = withErrorHandling(api.user.updateIdentity);
const processAvatar = withErrorHandling(api.user.processAvatar);

const MyProfile = () => {
  const { contactName, contactEmail } = useClientContext();

  const fileUploadRef = useRef<HTMLElement>(null);
  const dispatch: AppDispatch = useDispatch();

  const [avatarImage, setAvatarImage] = useState("");
  const [saving, setSaving] = useState(false);
  const [avatarAlert, setAvatarAlert] = useState("");
  const [avatarProcessing, setAvatarProcessing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const identityAvatarImage = useSelector(identityAvatarImageSelector);
  const identityLargeImage = useSelector(identityLargeImageSelector);
  const isImpersonation = useSelector(impersonationSelector);

  const { accountSettings: accountSettingsLocale } = useLocalization();

  useEffect(() => {
    if (identityLargeImage === false) {
      dispatch(loadLargeAvatar());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityLargeImage]);

  useEffect(() => {
    setAvatarImage(identityAvatarImage);
  }, [identityAvatarImage]);

  const handleSaveChanges = async () => {
    setSaving(true);

    const [identity, error] = await updateIdentity({ avatarImage });

    setSaving(false);

    if (error) {
      logError(error, "[MyProfile] handleSaveChanges");
      setAvatarAlert(accountSettingsLocale.account.saving_account_error);
      return;
    }

    dispatch(UserActions.UpdateIdentity(identity));
    setIsDirty(false);
  };

  const clearAvatarImage = () => {
    const input = fileUploadRef?.current?.firstChild as HTMLInputElement;
    if (input) {
      input.value = "";
    }
    setAvatarImage("");
    setIsDirty(false);
  };

  const handleCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    const { files } = e.target;
    if (files === null) {
      return;
    }

    const file = files[0];
    if (file === undefined) {
      return;
    }

    if (file.size > maxAvatarSizeBytes) {
      setAvatarAlert(accountSettingsLocale.account.too_large_image);
      clearAvatarImage();
      return;
    }

    setAvatarAlert("");
    setAvatarProcessing(true);

    fileReader.readAsDataURL(file);

    fileReader.onload = async (e) => {
      const result = e.target?.result as string;
      const [image, error] = await processAvatar(result);
      setAvatarProcessing(false);

      if (error) {
        logError(e, "[MyProfile] processAvatar");
        setAvatarAlert(accountSettingsLocale.account.loading_image_error);
        return;
      }

      setAvatarImage(image);
      setIsDirty(true);
    };
  };

  return (
    <LoaderBox loading={useSelector(loadingIdentitySelector)}>
      <Stack sx={{ py: 3, px: 4 }} spacing={3} width="100%">
        <Typography variant="h5">My Profile</Typography>
        {!!avatarAlert && (
          <Alert severity="error" onClose={() => setAvatarAlert("")}>
            {avatarAlert}
          </Alert>
        )}
        <Stack direction="row" spacing={8} sx={{ paddingTop: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="subtitle1">{accountSettingsLocale.account.my_photo}</Typography>
            <Box my={2}>
              <UserAvatar size={100} userName={contactName} avatarImage={avatarImage} />
            </Box>
            <LinearProgress
              sx={{
                marginBottom: "3px",
                visibility: avatarProcessing ? "visible" : "hidden",
              }}
            />
            <label htmlFor="contained-button-file">
              {!isImpersonation && (
                <Input
                  ref={fileUploadRef}
                  inputProps={{ accept: ".png, .jpg, .jpeg" }}
                  id="contained-button-file"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={handleCapture}
                />
              )}
              <Button
                variant="outlined"
                component="span"
                color="secondary"
                disabled={avatarProcessing || isImpersonation}
                sx={{ whiteSpace: "nowrap" }}
              >
                {accountSettingsLocale.account.upload_photo}
              </Button>
            </label>

            {avatarImage ? (
              <Button
                variant="text"
                color="secondary"
                sx={{ whiteSpace: "nowrap" }}
                onClick={() => clearAvatarImage()}
                disabled={avatarProcessing || isImpersonation}
              >
                {accountSettingsLocale.account.remove}
              </Button>
            ) : (
              <Typography noWrap color="text.secondary" variant="caption" sx={{ mt: 1 }}>
                {accountSettingsLocale.account.max_image_size}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="subtitle1">{accountSettingsLocale.account.personal_info}</Typography>
            <Stack spacing={2} pt={2}>
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Typography color="text.secondary" noWrap width="7rem">
                  Full Name
                </Typography>
                <Typography noWrap>{contactName}</Typography>
              </Stack>
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Typography color="text.secondary" noWrap width="7rem">
                  Email Address
                </Typography>
                <Typography noWrap>{contactEmail}</Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          sx={{ boxShadow: "unset", width: "7rem" }}
          disabled={isImpersonation || !isDirty}
          loading={saving}
          onClick={handleSaveChanges}
        >
          {accountSettingsLocale.account.save_changes}
        </LoadingButton>
      </Stack>
    </LoaderBox>
  );
};

export default MyProfile;
