import CompaniesIcon from "../../../../shared/icons/CompaniesIcon";
import FilterAutocomplete from "./FilterAutocomplete";

interface Props {
  funds: string[];
  selectedValues: string[];
  onSelected: (values: string[]) => void;
  disabled?: boolean;
}

const FundSelectFilter = ({ funds, onSelected, selectedValues, disabled }: Props) => {
  return (
    <FilterAutocomplete
      disabled={disabled}
      values={funds}
      selectedValues={selectedValues}
      onSelected={onSelected}
      Icon={CompaniesIcon}
      placeholder="Fund"
    />
  );
};

export default FundSelectFilter;
