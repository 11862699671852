import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { Link } from "react-router";
import ColorTag from "../../../../shared/components/ColorTag";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import TypographyTooltipEllipsis from "../../../../shared/components/TypographyTooltipEllipsis";
import { convertISODate } from "../../../../shared/utilities/dateUtils";
import { formatMoney } from "../../../../shared/utilities/formatters";
import { defined } from "../../../../shared/utilities/typeHelper";
import { CashFlowEntry, CashFlowTransaction } from "../../../api/types/dashboardTypes";
import { useClientContext } from "../../../contexts/ClientContext";
import { useLocalization } from "../../../hooks/useLocalization";
import { pageRoutes } from "../../../routes";
import { cashFlowPaymentStatusMap, impactTextMap } from "./cashFlowPageState";

interface Props {
  cashFlowEntries: CashFlowEntry[];
  loading?: boolean;
  showViewAllButton?: boolean;
}

interface MasterRowProps {
  row: CashFlowEntry;
  expanded: boolean;
  onToggleClick: (rowId: string) => void;
}

interface DetailRowProps {
  transaction: CashFlowTransaction;
}

interface ExpandedWrapperProps {
  expanded: boolean;
}

const rowWidths = {
  name: "20%",
  status: 130,
  investor: 240,
  fund: "auto",
  date: 120,
  amount: 200,
  unfunded: 200,
};

const MasterHeadRow = () => {
  const locale = useLocalization().dashboard.cashFlows.header;

  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell>{locale.name}</TableCell>
      <TableCell>{locale.status}</TableCell>
      <TableCell>{locale.investor}</TableCell>
      <TableCell>{locale.fund}</TableCell>
      <TableCell width={rowWidths.date}>{locale.due_date}</TableCell>
      <TableCell width={rowWidths.amount} align="right">
        {locale.amount}
      </TableCell>
      <TableCell width={rowWidths.unfunded} align="right">
        {locale.unfunded}
      </TableCell>
    </TableRow>
  );
};

const MasterRow = ({ row, expanded, onToggleClick }: MasterRowProps) => {
  const handleToggleButtonClick = () => onToggleClick(row.id);

  const { statusText, statusColor } = cashFlowPaymentStatusMap[row.status];
  const rowCurrency = row.transactions.find((t) => !!t.currency)?.currency || "USD";

  return (
    <TableRow hover={true} sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell padding="checkbox">
        <IconButton onClick={handleToggleButtonClick}>
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </TableCell>
      <TableCell width={rowWidths.name}>{row.name}</TableCell>
      <TableCell width={rowWidths.status}>
        <ColorTag text={statusText} color={statusColor} />
      </TableCell>
      <TableCell width={rowWidths.investor}>
        <TypographyTooltipEllipsis
          text={row.investorTitle}
          typographyProps={{ width: defined(rowWidths.investor) - 32 }}
        />
      </TableCell>
      <TableCell width={rowWidths.fund}>{row.fundName}</TableCell>
      <TableCell width={rowWidths.date}>{convertISODate(row.date)}</TableCell>
      <TableCell width={rowWidths.amount} align="right">
        <Typography variant="subtitle2">
          {formatMoney(row.amount * -1, rowCurrency, { currencySign: "accounting" })}
        </Typography>
      </TableCell>
      <TableCell width={rowWidths.unfunded} align="right">
        <Typography variant="subtitle2">
          {formatMoney(row.unfunded, rowCurrency, { currencySign: "accounting" })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const DetailRow = ({ transaction }: DetailRowProps) => {
  return (
    <TableRow
      hover={true}
      sx={(them) => ({
        "&.MuiTableRow-root": {
          borderBottom: `1px dashed ${them.palette.divider}`,
          "&:last-child": {
            borderBottom: 0,
          },
          td: {
            borderBottom: 0,
          },
        },
      })}
    >
      <TableCell width="auto" align="left" sx={{ pl: 2 }}>
        <Typography color="text.secondary">{transaction.detailsDescription}</Typography>
      </TableCell>
      <TableCell width={rowWidths.amount} sx={{ pr: 1 }} align="right">
        <Typography color="text.secondary">
          {formatMoney(transaction.netAmount * -1, transaction.currency, { currencySign: "accounting" })}
        </Typography>
      </TableCell>
      <TableCell width={rowWidths.unfunded} sx={{ pr: 1 }} align="right">
        <Typography color="text.secondary">{impactTextMap[transaction.impact]}</Typography>
      </TableCell>
    </TableRow>
  );
};

const ExpandedWrapper = ({ expanded, children }: PropsWithChildren<ExpandedWrapperProps>) => {
  const cellSx = { p: 0, border: expanded ? "" : "unset" };
  return (
    <TableRow>
      <TableCell sx={cellSx} colSpan={3} />
      <TableCell sx={cellSx} colSpan={8}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={(theme) => ({ bgColor: theme.palette.grey[100], mr: 1, my: 1.5 })}>
            <Table>
              <TableBody>{children}</TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const RowWithDetails = ({ row, expanded, onToggleClick }: MasterRowProps) => (
  <>
    <MasterRow row={row} expanded={expanded} onToggleClick={onToggleClick} />
    <ExpandedWrapper expanded={expanded}>
      {row.transactions.map((transaction) => (
        <DetailRow key={transaction.id} transaction={transaction} />
      ))}
    </ExpandedWrapper>
  </>
);

const ViewAllTableFooterRow = () => {
  const cashFlowsLocale = useLocalization().dashboard.cashFlows;
  const { clientCode } = useClientContext();

  return (
    <TableRow>
      <TableCell colSpan={2} sx={{ borderBottomWidth: 0 }}>
        <Button
          component={Link}
          to={`/${clientCode}/${pageRoutes.dashboard}/cash-flows`}
          variant="text"
          endIcon={<ArrowForwardRoundedIcon />}
        >
          {cashFlowsLocale.actions.view_all}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const EmptyDataRow = () => {
  const cashFlowsLocale = useLocalization().dashboard.cashFlows;
  return (
    <TableRow>
      <TableCell colSpan={8} align="center">
        <Typography color="text.secondary">{cashFlowsLocale.no_data}</Typography>
      </TableCell>
    </TableRow>
  );
};

const CashFlowsTable = ({ cashFlowEntries, showViewAllButton, loading }: Props) => {
  const [expandedRowIds, setExpandedRowIds] = useState(
    Object.fromEntries(cashFlowEntries.map((row) => [row.id, false]))
  );

  const handleRowToggle = (id: string) => setExpandedRowIds((rowIds) => ({ ...rowIds, [id]: !rowIds[id] }));

  if (loading) {
    return <InlineLoader />;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <MasterHeadRow />
        </TableHead>
        <TableBody>
          {cashFlowEntries.length > 0 ? (
            cashFlowEntries.map((row) => (
              <RowWithDetails
                key={row.id}
                row={row}
                expanded={!!expandedRowIds[row.id]}
                onToggleClick={handleRowToggle}
              />
            ))
          ) : (
            <EmptyDataRow />
          )}
        </TableBody>
        {showViewAllButton && (
          <TableFooter>
            <ViewAllTableFooterRow />
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default CashFlowsTable;
