import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { logError } from "../../../../../shared/logging";
import { localStorageService } from "../../../../../shared/services/storage";
import { sumBy } from "../../../../../shared/utilities/arrayHelper";
import { GridColDefConfig } from "../../../../store/state/inboxStructure/types";

const flexWidthColumns = ["fund", "recipient", "subject", "categoryId", "sendDate"];

const calculateColumnConfigs = (gridApi: GridApiPremium): GridColDefConfig[] => {
  const actualColumnWidths: Array<[string, number]> = gridApi
    .getAllColumns()
    .filter((col) => flexWidthColumns.includes(col.field))
    .map((col) => [col.field, col.computedWidth]);

  const totalWidth = sumBy(actualColumnWidths, (col) => col[1]);

  return actualColumnWidths.map(([field, actualWidth]) => ({
    field,
    width: Math.round((actualWidth / totalWidth) * 100),
  }));
};

const STORAGE_KEY = "InboxGridColumnsConfig";

export const loadColumnConfigs = (): GridColDefConfig[] => {
  try {
    return localStorageService.getItemWithFallback<GridColDefConfig[]>(STORAGE_KEY, []);
  } catch (error) {
    logError(error, "loadColumnConfigs");
    return [];
  }
};

export const saveColumnConfigs = (gridApi: GridApiPremium) => {
  try {
    const configToSave = calculateColumnConfigs(gridApi);
    localStorageService.setItem<GridColDefConfig[]>(STORAGE_KEY, configToSave);
  } catch (error) {
    logError(error, "saveColumnConfigs");
  }
};

export const getColumnFlexWidth = (field: string, configItems: GridColDefConfig[]) => {
  try {
    const configItem = configItems.find((item) => item.field === field);
    return configItem ? configItem.width / 100 : undefined;
  } catch (error) {
    logError(error, "getColumnFlexWidth");
    return undefined;
  }
};
