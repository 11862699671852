import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BalanceFiltersData, DashboardState } from "../../../api/types/dashboardTypes";
import { fetchBalance } from "./thunk";

export const defaultBalanceFilters: BalanceFiltersData = {
  investors: [],
  funds: [],
  reportingDateIso: undefined,
  initialDateIso: undefined,
};

const initialState: DashboardState = {
  balanceLoading: false,
  balanceError: "",
  balanceData: undefined,
  balanceInitialData: undefined,
  balanceFilters: defaultBalanceFilters,
  balanceClient: undefined,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateBalanceFilters: (state, action: PayloadAction<Partial<BalanceFiltersData>>) => {
      Object.assign(state.balanceFilters, action.payload);
    },
    resetBalanceFilters: (state) => {
      state.balanceFilters = {
        investors: [],
        funds: [],
        reportingDateIso: state.balanceFilters?.initialDateIso,
        initialDateIso: state.balanceFilters?.initialDateIso,
      };
    },
    resetBalanceInitialData: (state) => {
      state.balanceInitialData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.balanceData = action.payload.data;
        state.balanceLoading = false;
        state.balanceError = "";
        state.balanceClient = action.payload.client;
        state.balanceFilters.reportingDateIso = action.payload.reportingDateIso;
        if (action.payload.updateReportingPeriods) {
          state.balanceInitialData = { reportingPeriods: action.payload.reportingPeriods };
        }
        if (action.payload.reportingPeriods.length > 0) {
          state.balanceFilters.initialDateIso = action.payload.reportingDateIso;
        }
      })
      .addCase(fetchBalance.rejected, (state, action) => {
        state.balanceError = action.error.message;
        state.balanceLoading = false;
      })
      .addCase(fetchBalance.pending, (state) => {
        state.balanceLoading = true;
      });
  },
});

export const { updateBalanceFilters, resetBalanceFilters, resetBalanceInitialData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
