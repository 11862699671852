import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Breakpoint from "../../../../shared/components/Breakpoint";
import { makeLighterBackgroundFromColor } from "../../../../shared/utilities/colorHelper";
import { Attachment } from "../../../store/state/messages/types";

interface Props {
  attachment: Attachment;
  onDownload: () => void;
  onPreview: () => void;
}

export const AttachmentComponent = (props: Props) => {
  const { attachment, onDownload, onPreview } = props;

  const parseFileName = () => {
    const name = attachment.fileName;
    const extensionIndex = name.lastIndexOf(".");
    if (extensionIndex === -1) {
      return [name, ""];
    } else {
      const fileNameWithoutExtension = name.slice(0, extensionIndex);
      const extension = name.slice(extensionIndex);
      return [fileNameWithoutExtension, extension];
    }
  };

  const [fileName, fileExtension] = parseFileName();

  return (
    <Box gap={2} display="grid" gridTemplateColumns="auto auto auto" alignItems="center" justifyContent="start">
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="center"
        sx={(theme) => ({
          backgroundColor: makeLighterBackgroundFromColor(theme.palette.info.main),
          borderRadius: "50%",
          height: "3rem",
          width: "3rem",
        })}
      >
        <DescriptionOutlinedIcon fontSize="medium" color="info" />
      </Box>

      <Box onClick={onPreview}>
        <Box
          width="100%"
          display="grid"
          gridTemplateColumns="1fr auto"
          sx={{
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {fileName}
          </Typography>
          <Typography>{fileExtension}</Typography>
        </Box>
        <Typography color={"secondary"}>{attachment.fileSizeAsString}</Typography>
      </Box>

      <Box alignItems={"start"} display="flex">
        <Breakpoint from="sm">
          <LoadingButton
            variant="text"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              backgroundColor: grey[100],
              borderRadius: "18px",
              px: "16px",
              mt: -2,
            })}
            onClick={onDownload}
          >
            Download
          </LoadingButton>
        </Breakpoint>
        <Breakpoint to="sm">
          <IconButton
            sx={{ mt: -2.5 }}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              onDownload();
            }}
          >
            <SaveAltOutlinedIcon />
          </IconButton>
        </Breakpoint>
      </Box>
    </Box>
  );
};
