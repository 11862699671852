import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import deepEqual from "fast-deep-equal";
import objectHash from "object-hash";
import { useCallback, useState } from "react";
import { Link } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { api } from "../../../../api/client";
import { useClientContext } from "../../../../contexts/ClientContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import { pageRoutes } from "../../../../routes";
import ModalPage from "../../../common/ModalPage";
import FiltersIcon from "../../common/FiltersIcon";
import NoDataResult from "../../common/NoDataResult";
import { CashFlowFiltersState, getInitialCashFlowFiltersState, mapFilterStateToRequest } from "../cashFlowPageState";
import CashFlowCard from "./CashFlowCard";
import CashFlowFiltersMobile from "./CashFlowFiltersMobile";

const CashFlowsMobilePage = () => {
  const locale = useLocalization().dashboard.cashFlows;
  const { clientCode } = useClientContext();
  const theme = useTheme();

  const [showFilters, setShowFilters] = useState(false);

  const [filterState, setFilterState] = useState(getInitialCashFlowFiltersState());

  const getCashFlows = useCallback(
    () => api.dashboard.getCashFlows(mapFilterStateToRequest(filterState)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectHash(filterState)]
  );

  const [cashFlows, fetchError, { isFetching }] = useFetch(getCashFlows);

  const handleFiltersClose = (newFilters: CashFlowFiltersState) => {
    setShowFilters(false);
    setFilterState(newFilters);
  };

  const filtersApplied = !deepEqual(filterState, getInitialCashFlowFiltersState());

  return (
    <ModalPage
      sxContent={{ py: 1, px: 2 }}
      open
      toolbarChildren={
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <IconButton component={Link} to={`/${clientCode}/${pageRoutes.dashboard}`} edge="start">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">{locale.title}</Typography>
            <IconButton onClick={() => setShowFilters(!showFilters)}>
              <FiltersIcon isApplied={filtersApplied} color={theme.palette.primary.main} />
            </IconButton>
          </Stack>

          <CashFlowFiltersMobile open={showFilters} onClose={handleFiltersClose} disabled={isFetching} />
        </>
      }
    >
      {fetchError && <DataLoadingFailed />}

      {(cashFlows === undefined || isFetching) && !fetchError && <InlineLoader />}

      {!isFetching && !fetchError && cashFlows?.length === 0 && <NoDataResult text={locale.no_data} />}

      {cashFlows !== undefined && !isFetching && !fetchError && cashFlows.length > 0 && (
        <Stack spacing={2}>
          {cashFlows.map((entry) => (
            <Box key={entry.id} py={1}>
              <CashFlowCard cashFlowEntry={entry} />
            </Box>
          ))}
        </Stack>
      )}
    </ModalPage>
  );
};

export default CashFlowsMobilePage;
