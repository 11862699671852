import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Card, CardContent, Divider, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import TypographyMultilineEllipsis from "../../../../../shared/components/TypographyMultilineEllipsis";
import { convertISODate } from "../../../../../shared/utilities/dateUtils";
import { formatMoney } from "../../../../../shared/utilities/formatters";
import { CashFlowEntry } from "../../../../api/types/dashboardTypes";
import { useLocalization } from "../../../../hooks/useLocalization";
import ModalPage from "../../../common/ModalPage";
import { cashFlowPaymentStatusMap } from "../cashFlowPageState";
import CashFlowCardHeader from "./CashFlowCardHeader";
import CashFlowDetails from "./CashFlowDetails";
import CashFlowValueWithTitle from "./CashFlowValueWithTitle";

interface CashFlowCardProps {
  cashFlowEntry: CashFlowEntry;
}

const CashFlowCard = ({ cashFlowEntry }: CashFlowCardProps) => {
  const theme = useTheme();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const cashFlowsLocale = useLocalization().dashboard.cashFlows;
  const subtitle = `${cashFlowsLocale.header.due_date}: ${convertISODate(cashFlowEntry.date) || "-"}`;

  const { statusText, statusColor } = cashFlowPaymentStatusMap[cashFlowEntry.status];
  const currency = cashFlowEntry.transactions.find((t) => !!t.currency)?.currency || "USD";
  const showDetails = cashFlowEntry.transactions.length > 0;

  const CardHeader = ({ titleVariant, bgColor }: { titleVariant: "h6" | "subtitle1"; bgColor?: string }) => (
    <CashFlowCardHeader
      title={cashFlowEntry.name}
      subtitle={subtitle}
      statusText={statusText}
      statusColor={statusColor}
      titleVariant={titleVariant}
      bgColor={bgColor}
    />
  );

  return (
    <Card onClick={() => setDetailsOpen(true)} className="balance-card" sx={{ width: "100%", height: "100%" }}>
      <Stack px={2} py={1}>
        <CardHeader titleVariant="subtitle1" />
      </Stack>
      <CardContent sx={{ pt: 0 }}>
        <Stack spacing={1}>
          <Stack spacing={0.5}>
            <TypographyMultilineEllipsis maxLines={2}>{cashFlowEntry.investorTitle}</TypographyMultilineEllipsis>
            <Typography variant="caption" color="textSecondary">
              {cashFlowEntry.fundName}
            </Typography>
          </Stack>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Stack direction="row" justifyContent="space-between">
            <Typography color="textPrimary">{cashFlowsLocale.header.amount}</Typography>
            <Typography variant="subtitle2" color="primary.main">
              {formatMoney(cashFlowEntry.amount * -1, currency, { currencySign: "accounting" })}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="textPrimary">{cashFlowsLocale.header.unfunded}</Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {formatMoney(cashFlowEntry.unfunded, currency, { currencySign: "accounting" })}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
      {detailsOpen && (
        <ModalPage
          sxContent={{ py: 2, px: 2 }}
          open
          toolbarChildren={
            <>
              <IconButton edge="start" onClick={() => setTimeout(() => setDetailsOpen(false), 0)}>
                <ArrowBackRoundedIcon />
              </IconButton>
              <Typography variant="h6" sx={{ ml: "50%", transform: "translate(-100%, 0)" }}>
                {cashFlowsLocale.card_details_title}
              </Typography>
            </>
          }
        >
          <Stack spacing={2}>
            <CardHeader titleVariant="h6" bgColor={theme.palette.background.grey} />
            <CashFlowValueWithTitle label={cashFlowsLocale.header.investor} value={cashFlowEntry.investorTitle} />
            <CashFlowValueWithTitle label={cashFlowsLocale.header.fund} value={cashFlowEntry.fundName} />
            <Stack direction="row" gap={16}>
              <CashFlowValueWithTitle
                label={cashFlowsLocale.header.amount}
                value={formatMoney(cashFlowEntry.amount * -1, currency, { currencySign: "accounting" })}
                valueColor="primary.main"
                valueVariant="h6"
              />
              <CashFlowValueWithTitle
                label={cashFlowsLocale.header.unfunded}
                value={formatMoney(cashFlowEntry.unfunded, currency, { currencySign: "accounting" })}
                valueVariant="h6"
              />
            </Stack>
            {showDetails && <Divider />}
            {showDetails && <CashFlowDetails cashFlowEntry={cashFlowEntry} />}
          </Stack>
        </ModalPage>
      )}
    </Card>
  );
};

export default CashFlowCard;
