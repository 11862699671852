import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { getStatusCodeFromError } from "../../api/axiosHelper";
import { RedirectKeys } from "../../auth/authTypes";
import AuthenticationFailed from "../../components/AuthenticationFailed";
import NoAccessPage from "../../components/NoAccessPage";
import FullScreenLoader from "../../components/fullScreenLoader/FullScreenLoader";
import { useLocalization } from "../../hooks/useLocalization";
import { logError } from "../../logging";
import { AnyObject } from "../../types";
import { handleRedirectUrlState } from "../../utilities/handleRedirectUrlState";
import { defined } from "../../utilities/typeHelper";
import { requestToken } from "../identity";
import { getAuthSettings } from "../settings";

enum AuthenticationState {
  Authenticated = "Authenticated",
  NotAuthenticated = "NotAuthenticated",
  AuthenticationFailed = "AuthenticationFailed",
  Forbidden = "Forbidden",
}

export const Authentication = () => {
  const navigate = useNavigate();
  const locale = useLocalization();
  const [authenticationState, setAuthenticationState] = useState(AuthenticationState.NotAuthenticated);

  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get(RedirectKeys.Code);
  const stateParam = urlParams.get(RedirectKeys.State);

  const authCodeProvided = () => !!authCode;

  const acquireToken = useCallback(
    async (userFlow: string | undefined) => {
      try {
        await requestToken(defined(authCode), userFlow);
        setAuthenticationState(AuthenticationState.Authenticated);
      } catch (error) {
        const authState =
          getStatusCodeFromError(error) === 403
            ? AuthenticationState.Forbidden
            : AuthenticationState.AuthenticationFailed;

        setAuthenticationState(authState);
      }
    },
    [authCode]
  );

  useEffect(() => {
    let state: AnyObject = {};

    if (stateParam) {
      try {
        state = JSON.parse(stateParam) as AnyObject;
      } catch (error) {
        logError(error, "[Authentication] Parsing state parameter");
      }
    } else {
      navigate("/");
    }

    if (authenticationState === AuthenticationState.NotAuthenticated) {
      if (state["flow"] === "password_reset") {
        acquireToken(getAuthSettings().userFlowPasswordReset);
      } else {
        acquireToken(state["userFlow"] as string | undefined);
      }
    }
  }, [authenticationState, stateParam, acquireToken, navigate]);

  if (!authCodeProvided()) {
    return <Navigate to="/" />;
  }

  switch (authenticationState) {
    case AuthenticationState.NotAuthenticated: {
      return <FullScreenLoader title={locale.authentication.title} subtitle={locale.authentication.subtitle} />;
    }
    case AuthenticationState.AuthenticationFailed: {
      return <AuthenticationFailed />;
    }
    case AuthenticationState.Forbidden: {
      return <NoAccessPage />;
    }
    case AuthenticationState.Authenticated: {
      const redirected = handleRedirectUrlState();
      if (!redirected) {
        navigate("/");
      }
      return null;
    }
    default: {
      logError("Unexpected AuthenticationState value", "[Authentication]");
      return null;
    }
  }
};

export default Authentication;
