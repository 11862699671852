import { Button, Grid2, Stack } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useState } from "react";
import { useSelector } from "react-redux";
import DateRangeSelect from "../../../../shared/components/DateRangeSelect";
import useDebounce from "../../../../shared/hooks/useDebounce";
import { useLocalization } from "../../../hooks/useLocalization";
import { financialFundsSelector, financialInvestorsSelector } from "../../../store/state/user/selectors";
import FundSelectFilter from "../../common/filters/FundSelectFilter";
import InvestorSelectFilter from "../../common/filters/InvestorSelectFilter";
import PaymentStatusFilter from "../../common/filters/PaymentStatusFilter";
import {
  allPaymentStatusCaptions,
  cashFlowFilterDateRanges,
  CashFlowFiltersState,
  getInitialCashFlowFiltersState,
} from "./cashFlowPageState";

interface Props {
  onChange: (update: Partial<CashFlowFiltersState>) => void;
  disabled?: boolean;
}

const CashFlowFilters = ({ onChange, disabled }: Props) => {
  const locale = useLocalization();

  const investors = useSelector(financialInvestorsSelector("CASH FLOWS"));
  const funds = useSelector(financialFundsSelector("CASH FLOWS"));

  const [state, setState] = useState(getInitialCashFlowFiltersState());

  const onChangeDebounced = useDebounce(onChange, 1000);

  const handleChange = (update: Partial<CashFlowFiltersState>) => {
    setState((prev) => ({ ...prev, ...update }));
    onChangeDebounced(update);
  };

  const handleClearFilters = () => {
    handleChange(getInitialCashFlowFiltersState());
  };

  const filtersApplied = !deepEqual(state, getInitialCashFlowFiltersState());

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={3}>
      <Grid2 container width="100%" spacing={2} columns={12} sx={{ maxWidth: "60rem" }}>
        <Grid2 size={3}>
          <InvestorSelectFilter
            investors={investors}
            disabled={disabled}
            selectedValues={state.selectedInvestors}
            onSelected={(selectedInvestors) => handleChange({ selectedInvestors })}
          />
        </Grid2>
        <Grid2 size={3}>
          <FundSelectFilter
            funds={funds}
            disabled={disabled}
            selectedValues={state.selectedFunds}
            onSelected={(selectedFunds) => handleChange({ selectedFunds })}
          />
        </Grid2>
        <Grid2 size={3}>
          <DateRangeSelect
            disabled={disabled}
            dateRange={state.selectedDateRange}
            onChange={(selectedDateRange) => handleChange({ selectedDateRange })}
            predefinedRanges={cashFlowFilterDateRanges}
          />
        </Grid2>
        <Grid2 size={3}>
          <PaymentStatusFilter
            disabled={disabled}
            selectedValues={state.selectedStatuses}
            onSelected={(selectedStatuses) => handleChange({ selectedStatuses })}
            values={allPaymentStatusCaptions}
          />
        </Grid2>
      </Grid2>
      {filtersApplied && (
        <Button variant="text" disabled={disabled} onClick={handleClearFilters}>
          {locale.dashboard.cashFlows.actions.clear_all}
        </Button>
      )}
    </Stack>
  );
};

export default CashFlowFilters;
