import { RootState } from "../../store";

export const messagesSelector = (state: RootState) => state.messages.messages;
export const currentMessageSelector = (state: RootState) => state.messages.currentMessage;
export const markAsImportantUpdatingSelector = (state: RootState) => state.messages.loading.markAsImportantUpdating;

export const filterLoadingSelector = (state: RootState) => state.messages.loading.filterConfiguration;
export const filterInvestorsSelector = (state: RootState) => state.messages.filterConfiguration?.investors;
export const filterFundsSelector = (state: RootState) => state.messages.filterConfiguration?.funds;
export const filterCategoriesSelector = (state: RootState) => state.messages.filterConfiguration?.categories;
