export interface MessagesState {
  messages: Message[];
  currentMessage?: Message;
  loading: MessageLoadingState;
  filterConfiguration?: FilterConfiguration;
}

export interface Message {
  id: string;
  type: MessageType;
  externalId: string;
  sourceMessageRequestId: string;
  recipient: Recipient;
  categoryId: string;
  validFrom?: string;
  validTo?: string;
  sendDate: string;
  content: Content;
  isRead: boolean;
  isImportant: boolean;
  tags?: EntityTag[] | undefined;
  messageLevel: string;
  hasAttachments: boolean;
  numberOfAttachments: number;
  attachments?: Attachment[];
  publishingStatus: MessagePublishingStatus;
}

export type MessagePublishingStatus = "Live" | "Preview";

export enum MessageType {
  "Letter" = "Letter",
  "Document" = "Document",
}

export interface Recipient {
  id: string;
  title: string;
  type: string;
}

export interface Content {
  subject: string;
  htmlBody?: string;
}

export interface SearchCriteria {
  paging: {
    page: number;
    size: number;
    totals: boolean;
  };
  isImportant: boolean | undefined;
  investors?: string[];
  entities?: EntityTag[];
  categories?: string[];
  from?: string;
  to?: string;
  query?: string;
}

export interface EntityTag {
  caption: string;
  childTags?: EntityTag[] | undefined;
}

export enum FilterConfigurationLoadingState {
  None = 0,
  Loading = 1,
  Loaded = 2,
  Error = 3,
}

export interface MessageLoadingState {
  markAsImportantUpdating: boolean;
  filterConfiguration: FilterConfigurationLoadingState;
}

export interface Attachment {
  id: string;
  fileName: string;
  fileSize: number;
  fileSizeAsString: string;
}

export interface CategoriesFilterConfiguration {
  key: string;
  value: string;
}

export interface FilterConfiguration {
  funds: EntityTag[];
  investors: string[];
  categories: CategoriesFilterConfiguration[];
}

export interface TranslateIdsRequest {
  externalMessageId: string;
  externalAttachmentId: string;
}

export interface TranslateIdsResponse {
  messageId: string;
  attachmentId: string;
}

export const systemTags = {
  Inbox: "Inbox",
  Important: "Important",
};

export const isSystemTag = (tag: string) => Object.values(systemTags).includes(tag);
