import { Divider, Stack, Typography } from "@mui/material";
import { formatMoney } from "../../../../../shared/utilities/formatters";
import { CashFlowTransaction } from "../../../../api/types/dashboardTypes";
import { impactTextMap } from "../cashFlowPageState";

interface CashFlowTransactionDetailsProps {
  transaction: CashFlowTransaction;
}

const CashFlowTransactionDetails = ({ transaction }: CashFlowTransactionDetailsProps) => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography color="textPrimary">{transaction.detailsDescription}</Typography>

        <Stack spacing={0.5} alignItems="flex-end">
          <Typography color="textPrimary">
            {formatMoney(transaction.netAmount * -1, transaction.currency, { currencySign: "accounting" })}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {impactTextMap[transaction.impact]}
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ borderStyle: "dashed" }} />
    </>
  );
};

export default CashFlowTransactionDetails;
