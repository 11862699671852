import ImportDataIcon from "@mui/icons-material/ExitToAppRounded";
import ImportExportIcon from "@mui/icons-material/ImportExportRounded";
import ExportDataIcon from "@mui/icons-material/OutputRounded";
import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import { MetricCellValueUpdate } from "../../../../shared/api/dataCollectionTypes";
import ActionsMenuButton from "../../../../shared/components/ActionsMenuButton";
import DataSubmissionStatusTag from "../../../../shared/components/dataCollection/DataSubmissionStatusTag";
import DataSubmissionForm from "../../../../shared/components/dataCollection/submissionForms/DataSubmissionForm";
import {
  addCellValueUpdatesAction,
  EditedFormValuesState,
  getFormUpdatesPerBlock,
  getInitialEditedFormValuesState,
  isEditedFormValuesStateEmpty,
} from "../../../../shared/components/dataCollection/submissionForms/editedFormValuesState";
import { ImportErrorsAlert } from "../../../../shared/components/dataCollection/submissionForms/portfolioMonitoring/ImportErrorsAlert";
import ImportSubmissionBlockToExcelDialog from "../../../../shared/components/dataCollection/submissionForms/portfolioMonitoring/ImportSubmissionBlockToExcelDialog";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../shared/hooks/useFetch";
import useToggleState from "../../../../shared/hooks/useToggleState";
import { logError } from "../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../shared/services/downloadFile";
import { defined } from "../../../../shared/utilities/typeHelper";
import { api } from "../../../api/client";
import {
  DataCollectionSubmissionDetails,
  ImportSubmissionBlockInputResponse,
} from "../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../contexts/ClientContext";
import { pageRoutes } from "../../../routes";
import { entityPermissionsSelector } from "../../../store/state/user/selectors";
import PageHeader from "../../common/PageHeader";
import SubmitConfirmationDialog from "./SubmitConfirmationDialog";

const saveDataSubmissionLayoutBlockInput = withErrorHandling(api.dataCollection.saveDataSubmissionLayoutBlockInput);
const exportToExcel = withErrorHandling(api.dataCollection.exportSubmissionBlockInputToExcel);

const DataSubmissionPage = () => {
  const { id } = useParams();
  const { clientCode } = useClientContext();
  const entityPermissions = useSelector(entityPermissionsSelector);
  const { sendNotificationError } = useNotificationContext();

  const [formRenderKey, toggleFormRenderKey] = useToggleState(false);
  const [isSaving, setSaving] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [editedFormValues, setEditedFormValues] = useState<EditedFormValuesState>(getInitialEditedFormValuesState());
  const [showSubmitConfirmationDialog, setShowSubmitConfirmationDialog] = useState(false);
  const [showImportDataDialog, setShowImportDataDialog] = useState(false);
  const [importErrors, setImportErrors] = useState<string[]>([]);

  const getDataSubmissionDetails = useCallback(() => api.dataCollection.getDataSubmissionDetails(defined(id)), [id]);

  const [
    dataSubmission,
    fetchSubmissionError,
    { isFetching: isFetchingSubmission, setData: setDataSubmission, fetch: fetchSubmission },
  ] = useFetch(getDataSubmissionDetails);

  const handleBlockCellValuesEdit = useCallback((blockId: string, cellValueUpdates: MetricCellValueUpdate[]) => {
    setEditedFormValues(addCellValueUpdatesAction(blockId, cellValueUpdates));
  }, []);

  const hasPermissionsToEdit = useMemo(
    () =>
      entityPermissions.some(
        (p) =>
          !!dataSubmission?.recipientObjectId &&
          p.objectId === dataSubmission.recipientObjectId &&
          p.role === "Portfolio_Monitoring_Portal_Member"
      ),
    [dataSubmission?.recipientObjectId, entityPermissions]
  );

  const isSubmissionEditable = useMemo(
    () => hasPermissionsToEdit && dataSubmission?.status === "Pending",
    [dataSubmission?.status, hasPermissionsToEdit]
  );

  if (fetchSubmissionError) {
    logError(fetchSubmissionError, "[DataSubmissionPage] getDataSubmissionDetails");
    return <DataLoadingFailed title="Could not load data submission details" />;
  }

  if (isFetchingSubmission || dataSubmission === undefined) {
    return <InlineLoader />;
  }

  const handleSubmitClick = () => {
    setShowSubmitConfirmationDialog(true);
  };

  const handleSaveClick = async () => {
    const formUpdates = getFormUpdatesPerBlock(editedFormValues);
    let updatedSubmission: DataCollectionSubmissionDetails | undefined = undefined;

    setSaving(true);

    for (const [blockId, payload] of formUpdates) {
      const [resp, error] = await saveDataSubmissionLayoutBlockInput(defined(id), blockId, payload);

      if (error) {
        logError(error, `[DataSubmissionPage] saveDataSubmissionLayoutBlockInput (${id}:${blockId})`);
        sendNotificationError("Failed to save form values");
        continue;
      }

      updatedSubmission = resp;
    }

    setSaving(false);

    if (updatedSubmission) {
      setEditedFormValues(getInitialEditedFormValuesState());
      setDataSubmission(updatedSubmission);
      toggleFormRenderKey();
    }
  };

  const handleSubmitted = () => {
    setShowSubmitConfirmationDialog(false);
    fetchSubmission();
  };

  const handleImport = (importResp: ImportSubmissionBlockInputResponse) => {
    setShowImportDataDialog(false);

    if (importResp.errors.length > 0) {
      setImportErrors(importResp.errors);
    }

    if (importResp.updatedSubmission !== undefined) {
      setDataSubmission(importResp.updatedSubmission);
      toggleFormRenderKey();
    }
  };

  const handleCancelClick = () => {
    setEditedFormValues(getInitialEditedFormValuesState());
    toggleFormRenderKey();
  };

  const handleImportDataClick = () => {
    setShowImportDataDialog(true);
  };

  const handleExportDataClick = async () => {
    if (!importableBlock) {
      return;
    }

    setExporting(true);
    const [fileDownloadInfo, error] = await exportToExcel(dataSubmission.id, importableBlock.blockId);
    setExporting(false);

    if (error) {
      logError(error, "[DataSubmissionPage] exportSubmissionBlockInputToExcel");
      sendNotificationError("Failed to export submission form");
      return;
    }

    downloadFileFromUrl(fileDownloadInfo.downloadUrl);
  };

  const importableBlock = dataSubmission.blockContents.find((block) => block.capabilities.includes("ExcelImport"));

  const isSaveDisabled = isEditedFormValuesStateEmpty(editedFormValues);
  const isSubmitDisabled = !isSaveDisabled || isSaving;
  const isImportDisabled = !isSaveDisabled || isSaving;

  return (
    <>
      <PageHeader
        title={dataSubmission.requestName}
        titleAdornment={<DataSubmissionStatusTag status={dataSubmission.status} />}
        subtitle="Data Submission"
        backButtonLink={`/${clientCode}/${pageRoutes.dataCollection}`}
      >
        {isSubmissionEditable && (
          <Stack direction="row" spacing={1}>
            {importableBlock !== undefined && (
              <ActionsMenuButton
                text="Import/Export"
                variant="outlined"
                color="secondary"
                icon={<ImportExportIcon />}
                disabled={isImportDisabled}
                loading={isExporting}
                items={[
                  { label: "Import Data", icon: <ImportDataIcon />, onClick: handleImportDataClick },
                  { label: "Export Submission Form", icon: <ExportDataIcon />, onClick: handleExportDataClick },
                ]}
              />
            )}
            <Button variant="text" color="secondary" disabled={isSaveDisabled} onClick={handleCancelClick}>
              Cancel
            </Button>
            <LoadingButton variant="contained" loading={isSaving} disabled={isSaveDisabled} onClick={handleSaveClick}>
              Save
            </LoadingButton>
            <Button variant="outlined" disabled={isSubmitDisabled} onClick={handleSubmitClick}>
              Submit
            </Button>
          </Stack>
        )}
      </PageHeader>

      <ImportErrorsAlert errors={importErrors} onClose={() => setImportErrors([])} />

      <DataSubmissionForm
        key={formRenderKey.toString()}
        recipientObjectId={dataSubmission.recipientObjectId}
        layout={dataSubmission.layout}
        blockContents={dataSubmission.blockContents}
        isSubmissionEditable={isSubmissionEditable}
        metricExtensionsService={{
          searchObjectMetricExtensions: api.dataCollection.searchObjectMetricExtensions,
          createObjectMetricExtensions: api.dataCollection.createObjectMetricExtensions,
        }}
        onBlockCellValuesEdit={handleBlockCellValuesEdit}
      />

      <SubmitConfirmationDialog
        submission={dataSubmission}
        open={showSubmitConfirmationDialog}
        onClose={() => setShowSubmitConfirmationDialog(false)}
        onSubmitted={handleSubmitted}
      />

      {showImportDataDialog && importableBlock !== undefined && (
        <ImportSubmissionBlockToExcelDialog<DataCollectionSubmissionDetails>
          submissionImportService={{
            exportSubmissionBlockInputToExcel: api.dataCollection.exportSubmissionBlockInputToExcel,
            importSubmissionBlockInputFromExcel: api.dataCollection.importSubmissionBlockInputFromExcel,
          }}
          submissionId={dataSubmission.id}
          blockId={importableBlock.blockId}
          onClose={() => setShowImportDataDialog(false)}
          onImport={handleImport}
        />
      )}
    </>
  );
};

export default DataSubmissionPage;
