import { green, lime, red } from "@mui/material/colors";
import {
  anytime,
  DescriptiveDateRange,
  lastMonth,
  lastQuarter,
  lastYear,
  next30Days,
  nextQuarter,
  nextSevenDays,
  thisMonth,
  thisQuarter,
  thisYear,
  today,
} from "../../../../shared/utilities/dateFilterHelper";
import { CashFlowPaymentStatus, CashFlowRequest, CashFlowTransactionImpact } from "../../../api/types/dashboardTypes";

export const cashFlowFilterDateRanges: DescriptiveDateRange[] = [
  anytime,
  today,
  nextSevenDays,
  next30Days,
  nextQuarter,
  thisMonth,
  thisQuarter,
  thisYear,
  lastMonth,
  lastQuarter,
  lastYear,
];

export interface CashFlowFiltersState {
  selectedInvestors: string[];
  selectedFunds: string[];
  selectedDateRange: DescriptiveDateRange | undefined;
  selectedStatuses: string[];
}

export const getInitialCashFlowFiltersState = (): CashFlowFiltersState => ({
  selectedInvestors: [],
  selectedFunds: [],
  selectedDateRange: undefined,
  selectedStatuses: [],
});

export const mapPaymentStatusCaptions = (statusCaptions: string[]): CashFlowPaymentStatus[] =>
  statusCaptions
    .map(
      (caption) => Object.entries(cashFlowPaymentStatusMap).find(([, { statusText }]) => statusText === caption)?.[0]
    )
    .filter(Boolean) as CashFlowPaymentStatus[];

export const mapFilterStateToRequest = (state: CashFlowFiltersState): CashFlowRequest => ({
  investors: state.selectedInvestors,
  funds: state.selectedFunds,
  from: state.selectedDateRange?.from?.toISOString(),
  to: state.selectedDateRange?.to?.toISOString(),
  paymentStatuses: mapPaymentStatusCaptions(state.selectedStatuses),
});

export const cashFlowPaymentStatusMap: Record<CashFlowPaymentStatus, { statusText: string; statusColor: string }> = {
  Unpaid: { statusText: "Unpaid", statusColor: red[50] },
  PartlyPaid: { statusText: "Partially Paid", statusColor: lime[50] },
  Paid: { statusText: "Paid", statusColor: green[50] },
};

export const impactTextMap: Record<CashFlowTransactionImpact, string> = {
  None: "No Impact",
  Increases: "Increases",
  Decreases: "Decreases",
};

export const allPaymentStatusCaptions = Object.values(cashFlowPaymentStatusMap).map(({ statusText }) => statusText);
