import BoxWithLazyRender from "../../../shared/components/BoxWithLazyRender";
import { ImpersonationContactGroup } from "../../api/types/impersonationTypes";
import ImpersonationOptionsSection from "./ImpersonationOptionsSection";

interface Props {
  contactEmail: string;
  contactGroups: ImpersonationContactGroup[];
  filterText: string;
  handleImpersonationView: (email: string) => void;
  showFundName?: boolean;
}

const ImpersonationOptionsList = ({
  contactEmail,
  contactGroups,
  handleImpersonationView,
  filterText,
  showFundName,
}: Props) => {
  return (
    <BoxWithLazyRender chunkSize={40} sx={{ flex: 1, overflowY: "auto" }}>
      {contactGroups.map((contactGroup) => (
        <ImpersonationOptionsSection
          key={`${contactGroup.title}_${contactGroup.fundName ?? ""}`}
          contactGroup={contactGroup}
          filterText={filterText}
          contactEmail={contactEmail}
          onContactViewClick={handleImpersonationView}
          showFundName={showFundName}
        />
      ))}
    </BoxWithLazyRender>
  );
};

export default ImpersonationOptionsList;
