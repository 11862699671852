import { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router";
import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import { useLocalization } from "../hooks/useLocalization";
import { RedirectKeys } from "./authTypes";
import { requestSignupToken } from "./identity";

type SignUpStatus = "not_signed_up" | "signed_up" | "sign_up_failed";

const SignUp = () => {
  const locale = useLocalization();
  const [searchParams] = useSearchParams();

  const authCode = searchParams.get(RedirectKeys.Code);

  const [signUpState, setSignUpState] = useState<SignUpStatus>("not_signed_up");

  useEffect(() => {
    if (authCode) {
      const acquireSignupToken = async () => {
        try {
          const response = await requestSignupToken(authCode);
          if (response) {
            onSucceededAuth();
          }
        } catch {
          setSignUpState("sign_up_failed");
        }
      };

      acquireSignupToken();
    }
  }, [authCode]);

  const onSucceededAuth = () => {
    setSignUpState("signed_up");
  };

  if (!authCode) {
    return <Navigate to="/" />;
  }

  switch (signUpState) {
    case "not_signed_up": {
      return <FullScreenLoader title={locale.pages.signup.title} subtitle={locale.pages.signup.subtitle} />;
    }
    case "signed_up": {
      return <Navigate to={{ pathname: "/", search: "" }} replace />;
    }
    case "sign_up_failed": {
      return <Navigate to={{ pathname: "/error", search: "" }} />;
    }
    default: {
      return null;
    }
  }
};

export default SignUp;
