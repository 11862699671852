import { Box, Checkbox, List, ListItem, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { Contact } from "../../../../../api/types/settingsTypes";
import { systemRoleToDisplayText } from "../../../../../utilities/rolesHelper";
import { useSettingsContext } from "../../../SettingsContext";

interface Props {
  contact: Contact;
  fundName: string;
}

interface PermissionItemProps {
  title?: string;
  boldTitle?: boolean;
}

const PermissionItem = ({ title, boldTitle, children }: PropsWithChildren<PermissionItemProps>) => (
  <ListItem
    sx={(theme) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      flexGrow: 1,
      gap: theme.spacing(1),
      padding: theme.spacing(0, 1),
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      backgroundColor: boldTitle ? "#F4F5F7" : undefined,
    })}
  >
    <Typography fontWeight={boldTitle ? "bold" : "normal"} sx={{ flexGrow: 1, maxWidth: "10rem" }}>
      {title}
    </Typography>

    {children}
  </ListItem>
);

const ViewPermissions = ({ contact, fundName }: Props) => {
  const { accessCategories, roles } = useSettingsContext();

  const contactRole = contact.permissions.roles[0];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
        width: "100%",
        minWidth: "30rem",
        mt: ".5rem",
      }}
    >
      <>
        <Typography variant="subtitle1" sx={{ mt: ".5rem" }}>
          Access
        </Typography>
        <List disablePadding>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "nowrap",
              pl: "8rem",
              gap: "1.5rem",
            }}
          >
            <Typography variant="subtitle2">{fundName}</Typography>
          </ListItem>
          {accessCategories.map((category) => (
            <PermissionItem key={`item-${category.name}`} title={category.name}>
              <Box display="flex" justifyContent="space-between">
                <Checkbox
                  color="primary"
                  checked={contact.permissions.categories.includes(category.externalId)}
                  disabled
                />
              </Box>
            </PermissionItem>
          ))}
          <PermissionItem key="role" title="Role" boldTitle>
            {contactRole && (
              <Typography sx={{ fontWeight: "bold", py: 1 }}>{systemRoleToDisplayText(roles, contactRole)}</Typography>
            )}
          </PermissionItem>
        </List>
      </>
    </Box>
  );
};

export default ViewPermissions;
