import axios from "axios";
import { RedirectKeys } from "../../shared/auth/authTypes";
import { authState } from "../../shared/auth/b2c/redirects";
import { logError } from "../../shared/logging";
import { authCookieExists } from "../../shared/utilities/cookies";
import { pageRoutes } from "../routes";
import { setAuthMode } from "../variables";

export enum ImpersonationDataKeys {
  Impersonation = "impersonation",
  Client = "client",
  User = "user",
  Target = "target",
  Fund = "fund",
}

interface StateParams {
  [ImpersonationDataKeys.Impersonation]?: boolean;
  [ImpersonationDataKeys.User]?: string;
  [ImpersonationDataKeys.Client]?: string;
  [ImpersonationDataKeys.Target]?: string;
}

let impersonationInterceptor: number | undefined;

export const restoreImpersonationSearchParams = (impersonationSerachParams: URLSearchParams) => {
  if (impersonationSerachParams.has(ImpersonationDataKeys.Impersonation)) {
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${impersonationSerachParams.toString()}`
    );
  }
};

export const cancelImpersonation = () => {
  if (impersonationInterceptor !== undefined) {
    axios.interceptors.request.eject(impersonationInterceptor);
  }

  setAuthMode("end_user");

  delete authState[ImpersonationDataKeys.Impersonation];
  delete authState[ImpersonationDataKeys.Client];
  delete authState[ImpersonationDataKeys.User];
  delete authState[ImpersonationDataKeys.Target];
  delete authState[ImpersonationDataKeys.Fund];
};

export const viewAs = (email: string, fund: string) => {
  const getPathName = () => {
    const { pathname } = window.location;
    const segments = pathname.split("/");
    const pageName = segments[2]?.toLowerCase();

    if (pageName === pageRoutes.inbox || pageName === pageRoutes.fundraising) {
      return segments.slice(0, 3).join("/"); // Remove inbox filters or fundraising details page
    }

    return pathname;
  };

  const reloadUrlParams = new URLSearchParams(window.location.search);
  reloadUrlParams.set(ImpersonationDataKeys.Impersonation, "true");
  reloadUrlParams.set(ImpersonationDataKeys.User, email);
  if (fund) {
    reloadUrlParams.set(ImpersonationDataKeys.Fund, fund);
  }

  window.location.href = `${window.location.origin}${getPathName()}?${reloadUrlParams.toString()}`;
};

export const handlePortalImpersonation = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const impersonation = !!urlParams.get(ImpersonationDataKeys.Impersonation);
  const user = urlParams.get(ImpersonationDataKeys.User);
  const target = urlParams.get(ImpersonationDataKeys.Target);
  const fund = urlParams.get(ImpersonationDataKeys.Fund);
  const client = (window.location.pathname ? window.location.pathname.slice(1) : "").split("/")[0];
  const isImpersonationRedirect = impersonation && user && client;
  const stateParam = urlParams.get(RedirectKeys.State);

  if (isImpersonationRedirect) {
    impersonationInterceptor = addImpersonationInterceptor(client, user, target ?? "Investor");
    setAuthMode("impersonation");

    if (authCookieExists()) {
      return;
    }

    authState[ImpersonationDataKeys.Impersonation] = true;
    authState[ImpersonationDataKeys.Client] = client;
    authState[ImpersonationDataKeys.User] = user;
    authState[ImpersonationDataKeys.Target] = target ?? "Investor";
    if (fund) {
      authState[ImpersonationDataKeys.Fund] = fund;
    }

    return;
  }

  if (stateParam && stateParam.indexOf(ImpersonationDataKeys.Impersonation) > -1) {
    try {
      const state = JSON.parse(stateParam) as StateParams;
      const impersonation = !!state[ImpersonationDataKeys.Impersonation];
      const client = state[ImpersonationDataKeys.Client];
      const user = state[ImpersonationDataKeys.User];
      const target = state[ImpersonationDataKeys.Target];
      if (impersonation && client && user) {
        impersonationInterceptor = addImpersonationInterceptor(client, user, target ?? "Investor");
        setAuthMode("impersonation");
      } else {
        logError(`Invalid impersonation 'state' URL param: ${stateParam}`, "[impersonationHandler]");
        setAuthMode("end_user");
      }
    } catch {
      setAuthMode("end_user");
    }

    return;
  }

  setAuthMode("end_user");
};

const addImpersonationInterceptor = (client: string, user: string, impersonationTarget: string): number => {
  return axios.interceptors.request.use((requestConfig) => {
    const impersonationHeaders = createImpersonationHeaders(client, user, impersonationTarget);
    impersonationHeaders.forEach(([key, value]) => {
      requestConfig.headers[key] = value;
    });
    return requestConfig;
  });
};

export const setImpersonationHeaders = (clientCode: string, userEmail: string, impersonationTarget: string) => {
  if (impersonationInterceptor !== undefined) {
    axios.interceptors.request.eject(impersonationInterceptor);
  }

  impersonationInterceptor = addImpersonationInterceptor(clientCode, userEmail, impersonationTarget);
};

export const createImpersonationHeaders = (
  clientCode: string,
  userEmail: string,
  impersonationTarget: string
): Array<[string, string]> => [
  ["x-entrilia-onBehalfOfClient", encodeURIComponent(clientCode)],
  ["x-entrilia-onBehalfOfUser", encodeURIComponent(userEmail)],
  ["x-entrilia-impersonationTarget", encodeURIComponent(impersonationTarget)],
];
