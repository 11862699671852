import BookIcon from "@mui/icons-material/BookRounded";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenterRounded";
import ContentCut from "@mui/icons-material/ContentCutRounded";
import DescriptionIcon from "@mui/icons-material/DescriptionRounded";
import InboxIcon from "@mui/icons-material/InboxRounded";
import LabelImportantIcon from "@mui/icons-material/LabelImportantRounded";
import LabelIcon from "@mui/icons-material/LabelRounded";
import AnnualMeetingsNoticesIcon from "../../../../shared/icons/AnnualMeetingsNoticesIcon";
import AnnualQuarterlyReportsIcon from "../../../../shared/icons/AnnualQuarterlyReportsIcon";
import ArticlePersonIcon from "../../../../shared/icons/ArticlePersonIcon";
import CapCallNoticeIcon from "../../../../shared/icons/CapCallNoticeIcon";
import CapitalAccountStatementIcon from "../../../../shared/icons/CapitalAccountStatementIcon";
import DistributionNoticeIcon from "../../../../shared/icons/DistributionNoticeIcon";
import FinancialStatementIcon from "../../../../shared/icons/FinancialStatementIcon";
import GeneralIcon from "../../../../shared/icons/GeneralIcon";
import QuarterlyCapitalStatementsIcon from "../../../../shared/icons/QuarterlyCapitalStatementsIcon";
import QuarterlyNewsIcon from "../../../../shared/icons/QuarterlyNewsIcon";
import TaxInformationIcon from "../../../../shared/icons/TaxInformationIcon";

interface Props {
  iconName: string;
  color?: "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

export const DynamicIcon = (props: Props) => {
  const { iconName, color } = props;

  switch (iconName) {
    case "ICON_LEGAL":
      return (
        <BookIcon color={color}>
          <ContentCut />
        </BookIcon>
      );
    case "ICON_NOTICE":
      return (
        <CapCallNoticeIcon color={color}>
          <ContentCut />
        </CapCallNoticeIcon>
      );
    case "ICON_REPORT":
      return (
        <FinancialStatementIcon color={color}>
          <ContentCut />
        </FinancialStatementIcon>
      );
    case "ICON_TAX":
      return (
        <TaxInformationIcon color={color}>
          <ContentCut />
        </TaxInformationIcon>
      );
    case "Inbox":
      return (
        <InboxIcon color={color}>
          <ContentCut />
        </InboxIcon>
      );
    case "Important":
      return (
        <LabelImportantIcon style={{ color: "rgba(255, 180, 0, 1)" }}>
          <ContentCut />
        </LabelImportantIcon>
      );
    case "ICON_DISTRIBUTION_NOTICE":
      return (
        <DistributionNoticeIcon color={color}>
          <ContentCut />
        </DistributionNoticeIcon>
      );
    case "ICON_OTHER":
      return (
        <DescriptionIcon color={color}>
          <ContentCut />
        </DescriptionIcon>
      );
    case "ICON_PCAPS":
      return (
        <CapitalAccountStatementIcon color={color}>
          <ContentCut />
        </CapitalAccountStatementIcon>
      );
    case "ICON_GENERAL":
      return (
        <GeneralIcon color={color}>
          <ContentCut />
        </GeneralIcon>
      );
    case "ICON_QUARTERLY_NEWS":
      return (
        <QuarterlyNewsIcon color={color}>
          <ContentCut />
        </QuarterlyNewsIcon>
      );
    case "ICON_QUARTERLY_CAPITAL_STATEMENTS":
      return (
        <QuarterlyCapitalStatementsIcon color={color}>
          <ContentCut />
        </QuarterlyCapitalStatementsIcon>
      );
    case "ICON_ANNUAL_QUARTERLY_REPORTS":
      return (
        <AnnualQuarterlyReportsIcon color={color}>
          <ContentCut />
        </AnnualQuarterlyReportsIcon>
      );
    case "ICON_ANNUAL_MEETING_NOTICES":
      return (
        <AnnualMeetingsNoticesIcon color={color}>
          <ContentCut />
        </AnnualMeetingsNoticesIcon>
      );
    case "ICON_ACQUISITION":
      return (
        <BusinessCenterIcon color={color}>
          <ContentCut />
        </BusinessCenterIcon>
      );
    case "ICON_LP":
      return (
        <ArticlePersonIcon color={color}>
          <ContentCut />
        </ArticlePersonIcon>
      );
    default:
      return (
        <LabelIcon color={color}>
          <ContentCut />
        </LabelIcon>
      );
  }
};

export default DynamicIcon;
