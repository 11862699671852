import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import useFetch from "../../../shared/hooks/useFetch";
import { logError } from "../../../shared/logging";
import { api } from "../../api/client";
import DataSubmissionsFilterPanel from "./DataSubmissionsFilterPanel";
import DataSubmissionsGrid from "./DataSubmissionsGrid";
import {
  applyFilters,
  DataSubmissionsFilterState,
  getInitialDataSubmissionsFilterState,
} from "./dataSubmissionsFilterState";

const ActiveDataSubmissionsList = () => {
  const [filterState, setFilterState] = useState<DataSubmissionsFilterState>(getInitialDataSubmissionsFilterState());

  const getDataSubmissions = useCallback(() => api.dataCollection.searchDataSubmissions({ statuses: ["Pending"] }), []);

  const [dataSubmissions, fetchSubmissionsError] = useFetch(getDataSubmissions);

  if (fetchSubmissionsError) {
    logError(fetchSubmissionsError, "[ActiveDataSubmissionsList] searchDataSubmissions");
    return <DataLoadingFailed title="Could not load data submissions" />;
  }

  const rows = applyFilters(dataSubmissions ?? [], filterState);

  return (
    <>
      <DataSubmissionsFilterPanel
        allRows={dataSubmissions ?? []}
        filteredRowsCount={rows.length}
        filterState={filterState}
        setFilterState={setFilterState}
        isLoading={dataSubmissions === undefined}
      />
      <DataSubmissionsGrid
        rows={rows}
        hiddenColumns={["submittedBy", "submittedAt"]}
        isLoading={dataSubmissions === undefined}
        noRowsText="You do not have active submissions to fill out"
      />
    </>
  );
};

export default ActiveDataSubmissionsList;
