import { AccessPermission, ImpersonationTargetType } from "../../../../shared/api/types";
import { ConsentAgreement, ConsentAgreementType } from "../../../../shared/components/consentAgreement/types";
import { FundraisingInfo } from "../../../api/types/fundraisingTypes";
import { AccessCategory } from "../../../api/types/settingsTypes";

export interface UserState {
  authenticated: boolean;
  email: string;
  permissions: ClientPermissionSet[];
  identity?: Identity;
  loading: IdentityLoading;
  selectedInvestorId?: string;
  isImpersonationMode: boolean;
  clientCode: string;
}

export interface UserPermissionsResponse {
  email: string;
  permissions: ClientPermissionSet[];
  impersonationTargetType?: ImpersonationTargetType;
}

export interface ClientPermissionSet {
  clientCode: string;
  clientTitle: string;
  contactName: string;
  contactEmail: string;
  investorsPermissions: InvestorsPermissionSet[];
  entityPermissions: EntityPermissionSet[];
  portalSettings: PortalSettings;
  unsignedConsents?: ConsentAgreement[];
  financialPermissions: AccessCategoryPermissions[];
  fundraisings: FundraisingInfo[];
  messageCategoryIds: string[];
  portfolioMonitoringApplication?: PortfolioMonitoringApplication;
  featureToggles: Partial<Record<FeatureToggleName, boolean>>;
}

interface PortfolioMonitoringApplication {
  dataCollection?: {
    enabled: boolean;
  };
}

type FeatureToggleName = "ShowNetIrrColumnInBalanceWidget";

export interface AccessCategoryPermissions {
  category: AccessCategory;
  investor: InvestorsWithFunds[];
}

interface FundOfInvestor {
  id: string;
  name: string;
  stage?: FundStage;
}

interface InvestorsWithFunds {
  investorTitle: string;
  funds: FundOfInvestor[];
}

export interface InvestorsPermissionSet {
  investorId: string;
  investorTitle: string;
  fundInvestors: InvestorFundPermissionSet[];
  investorLevelPermissions: AccessPermission[];
}

export interface EntityPermissionSet {
  objectId: string;
  objectName: string;
  role: EntityPortalRole;
}

type EntityPortalRole = "Portfolio_Monitoring_Portal_Viewer" | "Portfolio_Monitoring_Portal_Member";

export interface PortalSettings {
  enabledFeatures?: string;
  enabledPreviewOnlyFeatures?: string;
  appBarColor?: string;
  appBarTextColor?: string;
  mainColor?: string;
  logoTypeUrl?: string;
  logoTypeSize?: RelativeLogoSize;
  logoMarkUrl?: string;
  faviconUrl?: string;
  bannerUrl?: string;
  bannerFontColor?: string;
  supportEmail?: string;
  supportPhone?: string;
  contactInvitationEmailCompanyLogoUrl?: string;
  inboxPageName?: string;
}

export type RelativeLogoSize = "Small" | "Medium" | "Large";

export const allInvestorRelationPortalFeatures = [
  "Inbox",
  "Dashboards",
  "AccountSettingsViewer",
  "AccountSettingsEditor",
  "BankAccountsDetailsViewer",
  "BankAccountsDetailsEditor",
  "Membership",
  "PortfolioCompanies",
  "Fundraising",
  "DataCollection",
] as const;

export type InvestorRelationPortalFeatures = (typeof allInvestorRelationPortalFeatures)[number];

export interface InvestorFundPermissionSet {
  fundInvestorId: string;
  fundId: string;
  fundName: string;
  fundStage?: FundStage;
  permissions: AccessPermission[];
}

type FundStage = "Investment" | "Fundraising";

export interface Identity {
  id: string;
  name: string;
  phone: string;
  email: string;
  signInEmail: string;
  avatarImage?: string;
  largeImage?: boolean;
  consentAgreements?: ConsentAgreement[];
}

export interface UpdateIdentityRequest {
  name?: string;
  phone?: string;
  email?: string;
  avatarImage?: string;
  consentAgreements?: ConsentAgreement[];
}

export interface AcceptedConsentAgreement {
  type: ConsentAgreementType;
  version: number;
  name: string;
}

export interface IdentityLoading {
  identity: boolean;
  state?: IdentityLoadingState;
}

export enum IdentityLoadingState {
  None = 0,
  Loaded = 2,
  LoadingError = 3,
  LoadingErrorImpersonationPermissions = 4,
  AuthorizationOrAccessError = 5,
}

export type FinancialAccessCategory = "CASH FLOWS" | "BALANCE";
