import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Button, Grid2, IconButton } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useMatch } from "react-router";
import Breakpoint from "../../../shared/components/Breakpoint";
import ElevationHeader from "../../../shared/components/ElevationHeader";
import HorizontalFill from "../../../shared/components/HorizontalFill";
import UserAvatar from "../../../shared/components/UserAvatar";
import { useClientContext } from "../../contexts/ClientContext";
import { pageRoutes } from "../../routes";
import { identityAvatarImageSelector } from "../../store/state/user/selectors";
import FeatureBoundary from "../common/FeatureBoundary";
import ClientsSelector from "./ClientsSelector";
import SupportInfoHelp from "./SupportInfoHelp";
import UserMenu from "./UserMenu";
import MainMenu from "./mobile/MainMenu";

interface PageLinkProps {
  page: string;
  title: string;
  highlighted: boolean;
}

const PageLink = ({ page, title, highlighted }: PageLinkProps) => {
  const { clientCode } = useClientContext();

  return (
    <Grid2 pl={2} alignItems="center" display="flex">
      <Button
        component={Link}
        to={`/${clientCode}/${page}`}
        state={{ refresh: true }}
        color={highlighted ? "primary" : "inherit"}
      >
        {title}
      </Button>
    </Grid2>
  );
};

const Header = () => {
  const { contactName } = useClientContext();

  const avatarImage = useSelector(identityAvatarImageSelector);

  const { portalSettings } = useClientContext();
  const routeMatch = useMatch("/:client/:pageName/*");
  const pageName = routeMatch?.params["pageName"];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const containerTopPadding = useMemo(() => {
    switch (portalSettings?.logoTypeSize) {
      case "Large":
        return 0;
      case "Medium":
        return 0.5;
      default:
        return 1;
    }
  }, [portalSettings?.logoTypeSize]);

  return (
    <ElevationHeader>
      <AppBar
        position="sticky"
        sx={(theme) => ({ color: portalSettings?.appBarTextColor || theme.palette.common.white })}
      >
        <Grid2
          container
          width="100%"
          className="header"
          sx={(theme) => ({
            bgcolor: portalSettings?.appBarColor || theme.palette.common.black,
            py: containerTopPadding,
            px: 2,
            [theme.breakpoints.down("sm")]: {
              py: containerTopPadding + 0.5,
              px: 1.5,
            },
          })}
        >
          <Grid2 sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <Breakpoint to="sm">
              <Grid2 container width="100%">
                <IconButton onClick={handleMenu}>
                  <MenuIcon />
                </IconButton>
                <MainMenu anchorEl={anchorEl} onClose={handleClose} />
              </Grid2>
            </Breakpoint>
          </Grid2>
          <Grid2 alignItems="center" display="flex">
            <ClientsSelector />
          </Grid2>
          <Breakpoint from="sm">
            <Grid2 container width="100%" flex={1}>
              <FeatureBoundary required="Dashboards">
                <PageLink
                  title="Dashboard"
                  page={pageRoutes.dashboard}
                  highlighted={pageName === pageRoutes.dashboard}
                />
              </FeatureBoundary>
              <FeatureBoundary required="Inbox">
                <PageLink
                  title={portalSettings?.inboxPageName || "Inbox"}
                  page={`${pageRoutes.inbox}/Inbox/p0`}
                  highlighted={pageName === pageRoutes.inbox}
                />
              </FeatureBoundary>
              <FeatureBoundary required="PortfolioCompanies">
                <PageLink
                  title="Portfolio"
                  page={pageRoutes.portfolio}
                  highlighted={pageName === pageRoutes.portfolio}
                />
              </FeatureBoundary>
              <FeatureBoundary required="Fundraising">
                <PageLink
                  title="Fundraising"
                  page={pageRoutes.fundraising}
                  highlighted={pageName === pageRoutes.fundraising}
                />
              </FeatureBoundary>
              <FeatureBoundary required="DataCollection">
                <PageLink
                  title="Data Collection"
                  page={pageRoutes.dataCollection}
                  highlighted={pageName === pageRoutes.dataCollection}
                />
              </FeatureBoundary>
              <HorizontalFill />
              <Grid2 display="flex">
                <SupportInfoHelp />
                <IconButton sx={{ ml: 1 }} onClick={handleMenu}>
                  <UserAvatar size="large" userName={contactName} avatarImage={avatarImage} />
                </IconButton>
              </Grid2>
              <UserMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
            </Grid2>
          </Breakpoint>
        </Grid2>
      </AppBar>
    </ElevationHeader>
  );
};

export default Header;
