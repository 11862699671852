import { useCallback } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import { api } from "../../../api/client";
import { useLocalization } from "../../../hooks/useLocalization";
import TableContainerCard from "../common/TableContainerCard";
import CashFlowsTable from "./CashFlowsTable";

const LIMIT = 8;

const CashFlowsWidget = () => {
  const locale = useLocalization().dashboard.cashFlows;

  const getCashFlows = useCallback(() => api.dashboard.getCashFlows({ limit: LIMIT }), []);

  const [cashFlows, fetchError, { isFetching }] = useFetch(getCashFlows);

  if (fetchError) {
    logError(fetchError, "[CashFlowWidget] getCashFlows");
  }

  const showViewAllButton = cashFlows !== undefined && cashFlows.length === LIMIT;

  return (
    <TableContainerCard title={locale.title} tableWrapperProps={{ pt: 1 }}>
      {fetchError && <DataLoadingFailed bgColor="none" />}

      {(cashFlows === undefined || isFetching) && !fetchError && <InlineLoader />}

      {cashFlows !== undefined && !isFetching && !fetchError && (
        <CashFlowsTable cashFlowEntries={cashFlows} showViewAllButton={showViewAllButton} />
      )}
    </TableContainerCard>
  );
};

export default CashFlowsWidget;
