import { Stack, Typography } from "@mui/material";
import objectHash from "object-hash";
import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import { api } from "../../../api/client";
import { useLocalization } from "../../../hooks/useLocalization";
import BackToDashboardButton from "../BackToDashboardButton";
import CashFlowFilters from "./CashFlowFilters";
import CashFlowsTable from "./CashFlowsTable";
import { CashFlowFiltersState, getInitialCashFlowFiltersState, mapFilterStateToRequest } from "./cashFlowPageState";

const CashFlowsPage = () => {
  const locale = useLocalization().dashboard.cashFlows;

  const [filterState, setFilterState] = useState(getInitialCashFlowFiltersState());

  const getCashFlows = useCallback(
    () => api.dashboard.getCashFlows(mapFilterStateToRequest(filterState)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectHash(filterState)]
  );

  const [cashFlows, fetchError, { isFetching }] = useFetch(getCashFlows);

  const handleFiltersChange = useCallback(
    (update: Partial<CashFlowFiltersState>) => setFilterState((prev) => ({ ...prev, ...update })),
    []
  );

  if (fetchError) {
    logError(fetchError, "[CashFlowsPage] getCashFlows");
    return <DataLoadingFailed />;
  }

  return (
    <Stack spacing={2} sx={{ py: 2, px: 5 }}>
      <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-start">
        <BackToDashboardButton />
        <Typography variant="h5">{locale.title}</Typography>
      </Stack>

      <CashFlowFilters onChange={handleFiltersChange} disabled={isFetching} />

      <CashFlowsTable cashFlowEntries={cashFlows ?? []} showViewAllButton={false} loading={isFetching} />
    </Stack>
  );
};

export default CashFlowsPage;
