import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import { updateClientCode } from "../shared/api/clientApiContext";
import { authState } from "../shared/auth/b2c/redirects";
import { useAuth } from "../shared/auth/hooks";
import AuthenticationFailed from "../shared/components/AuthenticationFailed";
import ErrorBoundary from "../shared/components/ErrorBoundary";
import GlobalNotificationErrorSnackbar from "../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../shared/components/GlobalNotificationSnackbar";
import NoAccessPage from "../shared/components/NoAccessPage";
import FullScreenLoader from "../shared/components/fullScreenLoader/FullScreenLoader";
import { NotificationContextProvider } from "../shared/contexts/NotificationContext";
import { applyDateFnsLocaleFromBrowser } from "../shared/utilities/dateLocalization";
import Pages from "./Pages";
import ClientSelectionPage from "./components/clientSelection/ClientSelectionPage";
import ConsentAgreementContainer from "./components/consentAgreement/ConsentAgreementContainer";
import Header from "./components/header/Header";
import ImpersonationPanel from "./components/impersonation/ImpersonationPanel";
import { ClientContextProvider } from "./contexts/ClientContext";
import { redirectToB2CAuthWithImpersonation } from "./impersonation/impersonationAuth";
import { ImpersonationDataKeys } from "./impersonation/impersonationHandler";
import CustomThemeProvider from "./providers/CustomThemeProvider";
import { getUnsignedGeneralAgreements } from "./services/consentAgreementService";
import { UserActions } from "./store/state/user/actions";
import {
  identityLoadingStateSelector,
  impersonationSelector,
  permissionsSelector,
  userConsentAgreementsSelector,
} from "./store/state/user/selectors";
import { loadUserInfo } from "./store/state/user/thunks";
import { IdentityLoadingState } from "./store/state/user/types";
import { AppDispatch } from "./store/store";

// Do not change - B2C login pages rely on it for branding
export const defaultClientParam = "entrilia";

const App = () => {
  const dispatch: AppDispatch = useDispatch();

  const { client } = useParams();
  const clientParam = client ? client.toLocaleLowerCase() : undefined;

  const identityLoadingState = useSelector(identityLoadingStateSelector);
  const isImpersonationMode = useSelector(impersonationSelector);
  const permissions = useSelector(permissionsSelector);
  const userConsentAgreements = useSelector(userConsentAgreementsSelector);

  const [impersonationFundName, setImpersonationFundName] = useState("");

  useEffect(() => {
    if (isImpersonationMode) {
      const urlParams = new URLSearchParams(window.location.search);
      const fund = decodeURIComponent(urlParams.get(ImpersonationDataKeys.Fund) || "");
      if (fund) {
        setImpersonationFundName(fund);
      }
    }
  }, [isImpersonationMode]);

  useEffect(() => {
    if (clientParam) {
      updateClientCode(clientParam);
      dispatch(UserActions.UpdateClientCode(clientParam));
    }
  }, [clientParam, dispatch]);

  const onAuthenticated = useCallback(() => {
    dispatch(UserActions.Authenticated());
    dispatch(loadUserInfo(clientParam));
  }, [clientParam, dispatch]);

  authState["portalName"] = "Investor Portal";
  authState["client"] = clientParam;

  const isAuthenticated = useAuth(onAuthenticated, redirectToB2CAuthWithImpersonation);

  const clientPermissions = useMemo(() => {
    const result =
      clientParam === defaultClientParam
        ? permissions[0]
        : permissions.find((p) => p.clientCode.toLocaleLowerCase() === clientParam);

    if (result) {
      updateClientCode(result.clientCode);
    }
    return result;
  }, [clientParam, permissions]);

  if (!isAuthenticated) {
    return <FullScreenLoader title="Please wait." subtitle="You are being redirected for Authentication..." />;
  }

  if (identityLoadingState === IdentityLoadingState.AuthorizationOrAccessError) {
    return <NoAccessPage />;
  }

  if (identityLoadingState === IdentityLoadingState.LoadingErrorImpersonationPermissions) {
    return <AuthenticationFailed />;
  }

  if (identityLoadingState === IdentityLoadingState.LoadingError) {
    return <Navigate to="/error" />;
  }

  if (identityLoadingState !== IdentityLoadingState.Loaded) {
    return <FullScreenLoader title="Please wait." subtitle="Loading user's data..." />;
  }

  if (clientPermissions === undefined) {
    return <ClientSelectionPage />;
  }

  const consentAgreementsToAccept = [
    ...getUnsignedGeneralAgreements(userConsentAgreements ?? []),
    ...(clientPermissions.unsignedConsents ?? []),
  ];

  const showConsentAgreement =
    !isImpersonationMode && consentAgreementsToAccept.length > 0 && userConsentAgreements !== undefined;

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={applyDateFnsLocaleFromBrowser()}>
        <ClientContextProvider
          clientPermissions={clientPermissions}
          impersonationFundName={impersonationFundName}
          isImpersonationMode={isImpersonationMode}
        >
          <CustomThemeProvider>
            <NotificationContextProvider>
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Header />
                {showConsentAgreement ? (
                  <ConsentAgreementContainer consentAgreements={consentAgreementsToAccept} />
                ) : (
                  <Pages />
                )}
                {isImpersonationMode && <ImpersonationPanel />}
              </Box>
              <GlobalNotificationSnackbar />
              <GlobalNotificationErrorSnackbar />
            </NotificationContextProvider>
          </CustomThemeProvider>
        </ClientContextProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

export default App;
