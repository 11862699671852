import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Button, Drawer, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import CloseIconButton from "../../../shared/components/CloseIconButton";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../shared/hooks/useFetch";
import { authCookieExists } from "../../../shared/utilities/cookies";
import { api } from "../../api/client";
import { useClientContext } from "../../contexts/ClientContext";
import { useLocalization } from "../../hooks/useLocalization";
import { cancelImpersonation, restoreImpersonationSearchParams } from "../../impersonation/impersonationHandler";
import { UserActions } from "../../store/state/user/actions";
import { userEmailSelector } from "../../store/state/user/selectors";
import { loadUserInfo } from "../../store/state/user/thunks";
import { AppDispatch } from "../../store/store";
import ImpersonationSearchPanel from "./ImpersonationSearchPanel";
import ImpersonationHelpPopup from "./impersonationHelpPopup";

const ImpersonationPanel = () => {
  const { clientCode, contactName } = useClientContext();
  const userEmail = useSelector(userEmailSelector);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locale = useLocalization();
  const panelRef = useRef<HTMLElement>();

  const [showSearchPanel, setShowSearchPanel] = useState(false);
  const [helpPopupAnchorEl, setHelpPopupAnchorEl] = useState<HTMLElement | undefined | null>(null);
  const [searchParams] = useState<URLSearchParams>(new URLSearchParams(window.location.search));

  const [impersonationOptions, fetchError] = useFetch(api.impersonation.getImpersonationOptions);

  useEffect(() => {
    restoreImpersonationSearchParams(searchParams);
  }, [searchParams, location]);

  const onImpersonationCancel = () => {
    cancelImpersonation();
    if (!authCookieExists()) {
      navigate(`/${clientCode}/loggedoutImpersonation`);
    } else {
      dispatch(UserActions.UpdateImpersonation(false));
      dispatch(loadUserInfo(clientCode));
      navigate(`/${clientCode}`);
    }
  };

  const toggleSearchPanel = () => {
    setShowSearchPanel((show) => !show);
  };

  const closeSearchPanel = () => {
    setShowSearchPanel(false);
  };

  const panelTextColor = "#FFF";

  return (
    <Box
      ref={panelRef}
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        height: "3rem",
        left: "50%",
        bottom: "-1.5rem",
        position: "fixed",
        transform: "translate(-50%, -50%)",
        width: "auto",
        px: "2rem",
        py: "1rem",
        bgcolor: "#2196F3",
        gap: theme.spacing(1),
        borderRadius: "4px 4px 0px 0px",
        boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.06)",
      })}
    >
      <Typography color="white">{locale.impersonation.inspect_label}</Typography>

      <Select
        sx={{
          bgcolor: "#0B79D0",
          color: panelTextColor,
          maxWidth: "262px",
          maxHeight: "32px",
        }}
        onOpen={toggleSearchPanel}
        open={false}
        displayEmpty
        defaultValue={contactName}
        renderValue={() => contactName}
      >
        <MenuItem value={contactName} disabled>
          {contactName}
        </MenuItem>
      </Select>

      {helpPopupAnchorEl && (
        <ImpersonationHelpPopup
          onClose={() => {
            setHelpPopupAnchorEl(null);
          }}
          anchorEl={helpPopupAnchorEl}
        />
      )}

      <IconButton
        sx={{ color: panelTextColor }}
        onClick={() => {
          setHelpPopupAnchorEl(panelRef.current);
        }}
      >
        <HelpOutlineIcon fontSize="medium" />
      </IconButton>

      <Button
        sx={{ color: panelTextColor, borderColor: panelTextColor }}
        variant="outlined"
        onClick={onImpersonationCancel}
      >
        Stop Inspect
      </Button>

      <Drawer
        anchor="right"
        open={showSearchPanel}
        sx={(theme) => ({
          "& .MuiDrawer-paper": {
            p: 0,
            width: theme.spacing(78),
          },
        })}
      >
        <Stack height="100%" display="flex" flexDirection="column">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              pl: 2,
              pr: 1,
              py: 2,
              gap: 2,
            }}
          >
            <Typography variant="subtitle1"> User </Typography>
            <Typography color="text.secondary">{`${contactName} (${userEmail})`}</Typography>
            <CloseIconButton
              onClick={closeSearchPanel}
              color="secondary"
              sx={{ marginLeft: "auto" }}
              iconProps={{ fontSize: "medium" }}
            />
          </Box>

          {impersonationOptions === undefined && !fetchError && <InlineLoader />}

          {impersonationOptions !== undefined && !fetchError && (
            <ImpersonationSearchPanel email={userEmail} contactGroups={impersonationOptions.contactGroups} />
          )}
        </Stack>

        {!!fetchError && <DataLoadingFailed />}
      </Drawer>
    </Box>
  );
};

export default ImpersonationPanel;
