import { TreeNode } from "../../../components/common/TreeSelect/treeModel";
import { EntityTag } from "./types";

const childTagToTreeNode = (tag: EntityTag, parentTag: EntityTag, selected: boolean): TreeNode => ({
  id: `${parentTag.caption}_${tag.caption}`,
  label: tag.caption,
  selected,
  children: [],
  parentId: parentTag.caption,
});

const fundTagToTreeNode = (fundTag: EntityTag, selectedFunds: string[], selectedEntities: EntityTag[]): TreeNode => {
  const id = fundTag.caption;
  const label = fundTag.caption;

  if (selectedFunds.includes(fundTag.caption)) {
    return {
      id,
      label,
      selected: true,
      children: (fundTag.childTags ?? []).map((childTag) => childTagToTreeNode(childTag, fundTag, true)),
    };
  }

  const selectedEntity = selectedEntities.find((e) => e.caption === fundTag.caption);
  if (selectedEntity === undefined) {
    return {
      id,
      label,
      selected: false,
      children: (fundTag.childTags ?? []).map((childTag) => childTagToTreeNode(childTag, fundTag, false)),
    };
  }

  const selectedEntityCaptions = new Set<string>();
  (selectedEntity.childTags ?? []).forEach((childTag) => selectedEntityCaptions.add(childTag.caption));
  const allChildrenSelected = selectedEntityCaptions.size === (fundTag.childTags ?? []).length;

  return {
    id,
    label,
    selected: allChildrenSelected ? true : null,
    children: (fundTag.childTags ?? []).map((childTag) =>
      childTagToTreeNode(childTag, fundTag, selectedEntityCaptions.has(childTag.caption))
    ),
  };
};

export const fundsToTreeNodes = (
  allFunds: EntityTag[],
  selectedFunds: string[] | undefined,
  selectedEntities: EntityTag[] | undefined
): TreeNode[] => allFunds.map((fundTag) => fundTagToTreeNode(fundTag, selectedFunds || [], selectedEntities || []));

interface FundsAndEntities {
  funds: string[];
  entities: EntityTag[];
}

export const treeNodesToFundsAndEntities = (nodes: TreeNode[]): FundsAndEntities =>
  nodes.reduce<FundsAndEntities>(
    (result, node) => {
      switch (node.selected) {
        case true: {
          result.funds.push(node.label);
          return result;
        }
        case null: {
          result.entities.push({
            caption: node.label,
            childTags: node.children
              .filter((childNode) => childNode.selected)
              .map((childNode) => ({
                caption: childNode.label,
                childTags: undefined,
              })),
          });
          return result;
        }
        default:
          return result;
      }
    },
    { funds: [], entities: [] }
  );
