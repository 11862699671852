import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import UserAvatar from "../../../../shared/components/UserAvatar";
import { useClientContext } from "../../../contexts/ClientContext";
import { identityAvatarImageSelector } from "../../../store/state/user/selectors";

const UserComponent = () => {
  const { contactName, contactEmail } = useClientContext();

  const avatarImage = useSelector(identityAvatarImageSelector);

  return (
    <Stack direction="row" width="100%" spacing={1.5} alignItems="center">
      <UserAvatar size="large" userName={contactName} avatarImage={avatarImage} />
      <Stack spacing={0.5}>
        <Typography whiteSpace="break-spaces" variant="h6" color="primary">
          {contactName}
        </Typography>
        {contactEmail && (
          <Typography variant="caption" color="text.secondary" noWrap>
            {contactEmail}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default UserComponent;
