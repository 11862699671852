import { useLocation, useNavigate } from "react-router";
import { InvestorBankAccountDetails } from "../../../../api/types/bankAccountTypes";
import { useClientContext } from "../../../../contexts/ClientContext";

interface NewBankAccountPageLocationState {
  availableFundInvestorIds: string[];
}

interface ChangeFundAssignmentsPageNavigationState {
  fundInvestorId: string;
  fundName: string;
  bankAccountId: string;
  bankAccounts: InvestorBankAccountDetails[];
}

export const useBankSettingsNavigation = () => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToBankAccountsPage = () => navigate(`/${clientCode}/settings/lp/bank`);

  const navigateToNewBankAccountPage = (navigationState: NewBankAccountPageLocationState) => {
    navigate(`/${clientCode}/settings/lp/bank/new`, { state: navigationState });
  };

  const navigateToChangeFundAssignmentsPage = (navigationState: ChangeFundAssignmentsPageNavigationState) => {
    navigate(`/${clientCode}/settings/lp/bank/assign`, { state: navigationState });
  };

  const newBankAccountPageNavigationState = location.state as NewBankAccountPageLocationState | null;
  const changeFundAssignmentsPageNavigationState = location.state as ChangeFundAssignmentsPageNavigationState | null;

  return {
    navigateToBankAccountsPage,
    navigateToNewBankAccountPage,
    navigateToChangeFundAssignmentsPage,
    newBankAccountPageNavigationState,
    changeFundAssignmentsPageNavigationState,
  };
};
