import { Card, CardContent, Stack, StackProps, Typography } from "@mui/material";
import { JSX, PropsWithChildren } from "react";

interface Props {
  title?: string;
  firstLineElements?: JSX.Element | JSX.Element[] | null;
  tableWrapperProps?: Partial<StackProps>;
}

const TableContainerCard = ({ title, firstLineElements, tableWrapperProps, children }: PropsWithChildren<Props>) => (
  <Card variant="outlined">
    <CardContent sx={{ px: 0, pb: 0, "&.MuiCardContent-root": { pt: 1 }, "&:last-child": { pb: 0 } }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mx={1}
        spacing={1}
        pb={1}
        pt={0.5}
        pl={1}
      >
        {title && <Typography variant="h6">{title}</Typography>}
        {firstLineElements && firstLineElements}
      </Stack>
      <Stack spacing={1} {...tableWrapperProps}>
        {children}
      </Stack>
    </CardContent>
  </Card>
);

export default TableContainerCard;
