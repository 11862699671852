import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";
import { redirectToPasswordReset } from "../../../../../shared/auth/b2c/redirects";
import LoaderBox from "../../../../../shared/components/LoaderBox";
import {
  identitySignInEmailSelector,
  impersonationSelector,
  loadingIdentitySelector,
} from "../../../../store/state/user/selectors";

export const PasswordAuth = () => {
  const signInEmail = useSelector(identitySignInEmailSelector);
  const isImpersonation = useSelector(impersonationSelector);

  return (
    <LoaderBox loading={useSelector(loadingIdentitySelector)}>
      <Box
        sx={{
          py: "1.5rem",
          px: "2rem",
          width: "100%",
        }}
      >
        <Typography variant="h5" mb="1rem">
          Password & Authentication
        </Typography>
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle1" lineHeight="24px" py="0.5rem">
            Password
          </Typography>
          <Typography color="text.secondary" lineHeight="24px">
            This password is used for login to your account.
          </Typography>

          <Button
            variant="outlined"
            sx={{ width: "10rem", mt: "1.4rem" }}
            onClick={redirectToPasswordReset}
            disabled={isImpersonation}
          >
            Change password
          </Button>

          <Typography variant="subtitle1" lineHeight="24px" pt="2rem" pb="1rem">
            2FA Authentication
          </Typography>

          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              padding: "1rem",
              width: "70%",
              maxWidth: "650px",
              backgroundColor: grey[100],
              borderRadius: "4px",
              color: theme.palette.text.primary,
            })}
          >
            <EmailRoundedIcon fontSize="large" color="secondary" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".2rem",
              }}
            >
              <Typography variant="subtitle1">Email</Typography>
              <Typography color="text.secondary">Confirmation codes are sent to the email specified earlier</Typography>
              <Typography>{signInEmail}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </LoaderBox>
  );
};

export default PasswordAuth;
