import { Grid2, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import SearchField from "../../../shared/components/inputs/SearchField";
import { distinct, groupByToMap } from "../../../shared/utilities/arrayHelper";
import { ImpersonationContact, ImpersonationContactGroup } from "../../api/types/impersonationTypes";
import { useClientContext } from "../../contexts/ClientContext";
import { viewAs } from "../../impersonation/impersonationHandler";
import ImpersonationOptionsList from "./ImpersonationOptionsList";

const doContactsMatchFilterValue = (contacts: ImpersonationContact[], filterValue: string) =>
  contacts.some(
    (contact) => contact.email.toLowerCase().includes(filterValue) || contact.name.toLowerCase().includes(filterValue)
  );

const filterContactGroups = (
  contactGroups: ImpersonationContactGroup[],
  filterText: string
): ImpersonationContactGroup[] => {
  if (filterText.trim() === "") {
    return contactGroups;
  }

  const filterValue = filterText.trim().toLowerCase();

  return contactGroups.filter(
    (x) =>
      x.title.toLowerCase().includes(filterValue) ||
      (x.fundName ?? "").toLowerCase().includes(filterValue) ||
      doContactsMatchFilterValue(x.contacts, filterValue)
  );
};

interface Props {
  email: string;
  contactGroups: ImpersonationContactGroup[];
}

const ImpersonationSearchPanel = ({ email, contactGroups }: Props) => {
  const { impersonationFundName } = useClientContext();

  const contactGroupsByFundName = useMemo(
    () =>
      groupByToMap(
        contactGroups.filter((g) => Boolean(g.fundName)),
        (group) => group.fundName ?? ""
      ),
    [contactGroups]
  );

  const fundNames = useMemo(
    () => distinct(contactGroups.map((x) => x.fundName ?? "").filter(Boolean)).sort((a, b) => a.localeCompare(b)),
    [contactGroups]
  );

  const existingFundName = fundNames.includes(impersonationFundName || "") ? impersonationFundName : undefined;

  const [currentFundName, setCurrentFundName] = useState<string>(existingFundName || "");

  const [filterText, setFilterText] = useState("");

  const handleImpersonationView = (email: string) => {
    viewAs(email, currentFundName);
  };

  const handleFundChange = (fundName: string) => {
    setCurrentFundName(fundName);
    setFilterText("");
  };

  const contactGroupsOfFund = currentFundName
    ? (contactGroupsByFundName.get(currentFundName) ?? [])
    : [...contactGroups];

  const filteredContactGroups = filterContactGroups(contactGroupsOfFund, filterText);

  return (
    <>
      <Stack spacing={3}>
        <Grid2 container width="100%">
          <Grid2 size="grow" px={2}>
            <SearchField
              initialValue={filterText}
              onSearch={(value) => setFilterText(value)}
              debounceTimeMs={300}
              fullWidth
            />
          </Grid2>

          {fundNames.length > 0 && (
            <Grid2 size={5} px={2}>
              <Select
                sx={(theme) => ({
                  width: "100%",
                  "&.MuiMenu-list": {
                    py: theme.spacing(0.5),
                  },
                })}
                onChange={(e) => handleFundChange(e.target.value)}
                value={currentFundName}
                displayEmpty
              >
                <MenuItem value="">All Funds</MenuItem>
                {fundNames.map((fund) => (
                  <MenuItem key={fund} value={fund}>
                    {fund}
                  </MenuItem>
                ))}
              </Select>
            </Grid2>
          )}
        </Grid2>

        <Grid2 container width="100%" pb={2}>
          <Grid2 size={5.5} px={7}>
            <Typography variant="subtitle1">Name</Typography>
          </Grid2>

          <Grid2 size="grow">
            <Typography variant="subtitle1">Email</Typography>
          </Grid2>
        </Grid2>
      </Stack>

      <ImpersonationOptionsList
        handleImpersonationView={handleImpersonationView}
        contactEmail={email}
        filterText={filterText}
        contactGroups={filteredContactGroups}
        showFundName={!currentFundName}
      />
    </>
  );
};

export default ImpersonationSearchPanel;
