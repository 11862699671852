import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Avatar, Button, CardHeader, Skeleton, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { Link } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import HorizontalSwiper from "../../../../../shared/components/HorizontalSwiper";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { api } from "../../../../api/client";
import { useClientContext } from "../../../../contexts/ClientContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import { pageRoutes } from "../../../../routes";
import { cardHeaderPropsDefault } from "../../common/helpers";
import NoDataResult from "../../common/NoDataResult";
import CashFlowCard from "./CashFlowCard";

const CashFlowWidgetSkeleton = () => {
  return (
    <Stack>
      <CardHeader
        sx={{ ...cardHeaderPropsDefault, pb: 2 }}
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        title={<Skeleton animation="wave" height={20} width="40%" />}
      />
      <Skeleton sx={{ height: 190, mx: 2, borderRadius: 1 }} animation="wave" variant="rectangular" />
    </Stack>
  );
};

const LIMIT = 5;

const CashFlowsWidgetMobile = () => {
  const { clientCode } = useClientContext();

  const locale = useLocalization().dashboard.cashFlows;

  const getCashFlows = useCallback(() => api.dashboard.getCashFlows({ limit: LIMIT }), []);

  const [cashFlows, fetchError, { isFetching }] = useFetch(getCashFlows);

  if (fetchError) {
    logError(fetchError, "[CashFlowWidgetMobile] getCashFlows");
  }

  if (isFetching) {
    return <CashFlowWidgetSkeleton />;
  }

  const showData = !isFetching && !fetchError && cashFlows !== undefined && cashFlows.length > 0;

  return (
    <Stack>
      <CardHeader
        sx={cardHeaderPropsDefault}
        avatar={
          <Avatar sx={(theme) => ({ backgroundColor: theme.palette.primary.main })} aria-label="recipe">
            <TextSnippetIcon sx={(theme) => ({ color: theme.palette.primary.contrastText })} />
          </Avatar>
        }
        action={
          <Button
            component={Link}
            to={`/${clientCode}/${pageRoutes.dashboard}/cash-flows`}
            variant="text"
            disabled={!showData}
          >
            {locale.actions.view_all}
          </Button>
        }
        title={<Typography variant="h6">{locale.title}</Typography>}
      />

      {fetchError && <DataLoadingFailed bgColor="none" sx={{ px: 2 }} />}

      {!fetchError && cashFlows?.length === 0 && <NoDataResult text={locale.no_data} />}

      {showData && cashFlows !== undefined && (
        <HorizontalSwiper
          items={cashFlows.map((entry, i) => (
            <CashFlowCard key={"cc" + i} cashFlowEntry={entry} />
          ))}
        />
      )}
    </Stack>
  );
};

export default CashFlowsWidgetMobile;
