import { createAction, createReducer } from "@reduxjs/toolkit";
import { getAuthMode } from "../../../variables";
import { UserActionsEnum } from "./actions";
import { Identity, IdentityLoading, UserState } from "./types";

const initialState: UserState = {
  authenticated: false,
  permissions: [],
  email: "",
  loading: { identity: false },
  isImpersonationMode: getAuthMode() === "impersonation",
  clientCode: "",
};

export type UserPayload = Partial<UserState> | IdentityLoading | Identity;

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createAction<Partial<UserState>>(UserActionsEnum.LoadInfo), (state, action) => {
      return { ...state, ...action.payload };
    })
    .addCase(createAction(UserActionsEnum.Authenticated), (state) => {
      state.authenticated = true;
      return state;
    })
    .addCase(createAction<Identity>(UserActionsEnum.UpdateLoading), (state, action) => {
      state.loading = { ...state.loading, ...action.payload };
      return state;
    })
    .addCase(createAction<Identity>(UserActionsEnum.UpdateIdentity), (state, action) => {
      state.identity = action.payload;
      return state;
    })
    .addCase(createAction<string>(UserActionsEnum.UpdateWorkspace), (state, action) => {
      state.selectedInvestorId = action.payload;
      return state;
    })
    .addCase(createAction<boolean>(UserActionsEnum.Impersonation), (state, action) => {
      state.isImpersonationMode = action.payload;
      return state;
    })
    .addCase(createAction<string>(UserActionsEnum.UpdateClientCode), (state, action) => {
      state.clientCode = action.payload;
      return state;
    });
});
