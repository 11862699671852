import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import useFetch from "../../../shared/hooks/useFetch";
import { logError } from "../../../shared/logging";
import { api } from "../../api/client";
import DataSubmissionsFilterPanel from "./DataSubmissionsFilterPanel";
import DataSubmissionsGrid from "./DataSubmissionsGrid";
import {
  applyFilters,
  DataSubmissionsFilterState,
  getInitialDataSubmissionsFilterState,
} from "./dataSubmissionsFilterState";

const CompletedDataSubmissionsList = () => {
  const [filterState, setFilterState] = useState<DataSubmissionsFilterState>(getInitialDataSubmissionsFilterState());

  const getDataSubmissions = useCallback(
    () => api.dataCollection.searchDataSubmissions({ statuses: ["Completed"] }),
    []
  );

  const [dataSubmissions, fetchSubmissionsError] = useFetch(getDataSubmissions);

  if (fetchSubmissionsError) {
    logError(fetchSubmissionsError, "[CompletedDataSubmissionsList] searchDataSubmissions");
    return <DataLoadingFailed title="Could not load data submissions" />;
  }

  const rows = applyFilters(dataSubmissions ?? [], filterState);

  return (
    <>
      <DataSubmissionsFilterPanel
        allRows={dataSubmissions ?? []}
        filteredRowsCount={rows.length}
        filterState={filterState}
        setFilterState={setFilterState}
        isLoading={dataSubmissions === undefined}
      />
      <DataSubmissionsGrid
        rows={rows}
        hiddenColumns={["dueDate"]}
        isLoading={dataSubmissions === undefined}
        noRowsText="You do not have completed submissions"
      />
    </>
  );
};

export default CompletedDataSubmissionsList;
