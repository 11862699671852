import { Select } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CompaniesIcon from "../../../../../../shared/icons/CompaniesIcon";
import { useInboxStateContext } from "../../../../../contexts/InboxStateContext";
import { fundsToTreeNodes, treeNodesToFundsAndEntities } from "../../../../../store/state/messages/searchMapper";
import { filterFundsSelector } from "../../../../../store/state/messages/selectors";
import TreeSelect from "../../../../common/TreeSelect/TreeSelect";
import { TreeNode, getAllNodesFlat } from "../../../../common/TreeSelect/treeModel";

const FundTreeSelect = () => {
  const [open, setOpen] = useState(false);
  const funds = useSelector(filterFundsSelector);
  const { criteria, updateCriteria } = useInboxStateContext();

  const updateFundsAndEntities = useCallback(
    (newNodes: TreeNode[]) => {
      const { funds, entities } = treeNodesToFundsAndEntities(newNodes);
      updateCriteria({ funds, entities, page: 0 });
    },
    [updateCriteria]
  );

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  useEffect(() => {
    const expandedNodes = (funds || []).map((f) => f.caption);
    setExpandedNodes(expandedNodes);
  }, [funds]);

  const criteriaInvestors = (criteria.investors || []).map((i) => {
    return { caption: i };
  });

  const nodes = fundsToTreeNodes(funds || [], criteria.funds, criteriaInvestors);
  const isAnyNodeSelected = getAllNodesFlat(nodes).some((node) => node.selected === true);

  return (
    <Select
      open={open}
      startAdornment={<CompaniesIcon color="action" />}
      value={nodes}
      multiple
      renderValue={(nodesValue) => {
        const selectedLabels = getAllNodesFlat(nodesValue)
          .filter((node) => node.selected === true)
          .map((node) => node.label);

        return selectedLabels.length === 0 ? "Fund" : selectedLabels.join(", ");
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        flex: 1,
        ".MuiInputBase-input": {
          color: isAnyNodeSelected ? "text.primary" : "text.secondary",
        },
        ".MuiSelect-select": {
          pl: "5px",
        },
      }}
    >
      <TreeSelect
        nodes={nodes}
        expandedNodes={expandedNodes}
        onChange={updateFundsAndEntities}
        onExpandedNodesChange={setExpandedNodes}
      />
    </Select>
  );
};

export default FundTreeSelect;
