import PersonIcon from "@mui/icons-material/PersonOutline";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  TabularDataFieldConfigMap,
  balanceFieldSet,
  getBalanceFields,
} from "../../../../shared/components/balances/balanceFieldset";
import { logError } from "../../../../shared/logging";
import { distinct, groupByToMap } from "../../../../shared/utilities/arrayHelper";
import { convertToNumber } from "../../../../shared/utilities/numberHelper";
import { BalanceDataResponse } from "../../../api/types/dashboardTypes";
import { useClientContext } from "../../../contexts/ClientContext";
import { useLocalization } from "../../../hooks/useLocalization";
import JCurveIcon from "../../../icons/JCurveIcon";
import { featureTogglesSelector } from "../../../store/state/user/selectors";
import FundCellComponent from "./FundCellComponent";

interface Props {
  balanceDataResponse: BalanceDataResponse;
}

interface RowProps {
  fields: TabularDataFieldConfigMap[];
  showNetIrrCurve: boolean;
}

interface TitleRowProps {
  title: string;
  fieldsCount: number;
}

interface BalanceRowsProps extends RowProps {
  rowsData: {
    [key: string]: string;
  }[];
  reportingDates: Record<string, Date>;
}

interface TotalRowProps extends RowProps {
  totals: Record<string, number>;
  currencyCode: string;
}

const BalanceFieldComponents = {
  [balanceFieldSet.communicationGroupName.guid]: FundCellComponent,
};

const getNetIrrValue = (row: { [key: string]: string }): number => {
  const valueFromRow = row[balanceFieldSet.netIRR.guid];
  return convertToNumber(valueFromRow) ?? 0;
};

const netIrrToFakeJCurveProgress = (netIrr: number) => {
  if (netIrr < 0) {
    return 0.25;
  }

  if (netIrr > 1) {
    return 0.85;
  }

  if (netIrr > 0.5) {
    return 0.65;
  }

  if (netIrr > 0) {
    return 0.45;
  }

  return 0;
};

const BalanceTable = ({ balanceDataResponse }: Props) => {
  const { clientCode } = useClientContext();
  const featureToggles = useSelector(featureTogglesSelector);

  const showNetIrrCurve = useMemo(
    () => !!featureToggles.ShowNetIrrColumnInBalanceWidget,
    [featureToggles.ShowNetIrrColumnInBalanceWidget]
  );

  const fields = useMemo(
    () => getBalanceFields({ exclude: [balanceFieldSet.investorName.guid], clientCode }),
    [clientCode]
  );

  const rowsGrouped = useMemo(
    () =>
      groupByToMap(
        balanceDataResponse.balanceData ? balanceDataResponse.balanceData.data : [],
        (data) => data[balanceFieldSet.investorName.guid]
      ),
    [balanceDataResponse.balanceData]
  );

  const reportingDates = balanceDataResponse.fundReportingDates;

  const group = Array.from(rowsGrouped.keys());

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <HeaderRow fields={fields} showNetIrrCurve={showNetIrrCurve} />
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableRow-root": {
              "&:last-child": {
                td: {
                  borderBottom: 0,
                },
              },
            },
          }}
        >
          {group.map((title, i) => {
            const rowsData = rowsGrouped.get(title);
            const groupTitle = title || balanceFieldSet.investorName.guid;
            return (
              <Fragment key={i}>
                {i > 0 && <EmptyRow key={`er_${i}`} />}
                <TitleRow key={`tr_${i}`} title={groupTitle} fieldsCount={fields.length + 1} />
                {rowsData && (
                  <BalanceRows
                    key={`brs_${i}`}
                    fields={fields}
                    rowsData={rowsData}
                    reportingDates={reportingDates}
                    showNetIrrCurve={showNetIrrCurve}
                  />
                )}
              </Fragment>
            );
          })}
          {group.length === 0 && (
            <EmptyRow
              key="er"
              showNoDataMsg
              colSpan={fields.filter((f) => !f.config?.hidden).length + (showNetIrrCurve ? 3 : 2)}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BalanceRows = ({ fields, rowsData, reportingDates, showNetIrrCurve }: BalanceRowsProps) => {
  const totals: Record<string, number> = {};

  const allCurrencyCodes = distinct(
    rowsData.map((row) => row[balanceFieldSet.currencyCode.guid] ?? "").filter(Boolean)
  );

  return (
    <>
      {rowsData.map((row, i) => {
        const jCurveProgress = netIrrToFakeJCurveProgress(getNetIrrValue(row));
        return (
          <Fragment key={i}>
            <TableRow hover key={`br_${i}`}>
              <TableCell key="tec"></TableCell>
              {fields.map((field) => {
                const currencyCode = row[balanceFieldSet.currencyCode.guid] ?? "";
                const value = row[field.guid] as string;
                const config = field.config;
                if (config?.hidden) {
                  return <TableCell key={`hc_${i}`} width={0} />;
                }
                const formattedValue = config?.format ? config.format(value, currencyCode) : value || "";
                if (rowsData.length > 1 && value && config?.calculateTotal) {
                  const numValue = +value;
                  if (isNaN(numValue)) {
                    logError(
                      `Can't calculate total for non number value ${value}. FieldId: ${field.guid}`,
                      "[BalanceRows]"
                    );
                  }
                  totals[field.guid] = (totals[field.guid] || 0) + (numValue || 0);
                }

                const components = Object.entries(BalanceFieldComponents);
                const componentToRender = components.find(([key]) => key === field.guid)?.[1];
                const fundReportingDate = reportingDates[value];

                return (
                  <TableCell align={config?.align || "left"} key={`brc_${field.guid}_${i}`}>
                    {componentToRender ? (
                      componentToRender({
                        value: formattedValue,
                        reportingDate: fundReportingDate && new Date(fundReportingDate),
                      })
                    ) : (
                      <Typography>{formattedValue}</Typography>
                    )}
                  </TableCell>
                );
              })}

              {showNetIrrCurve && (
                <TableCell key="irr">
                  <JCurveIcon progress={jCurveProgress} width={65} height={16} />
                </TableCell>
              )}
            </TableRow>
          </Fragment>
        );
      })}

      {rowsData.length > 1 && Object.entries(totals).length > 0 && allCurrencyCodes.length < 2 && (
        <TotalRow
          key="btr"
          fields={fields}
          totals={totals}
          currencyCode={allCurrencyCodes[0] ?? ""}
          showNetIrrCurve={showNetIrrCurve}
        />
      )}
    </>
  );
};

const TitleRow = ({ title, fieldsCount }: TitleRowProps) => {
  return (
    <TableRow key="mtr">
      <TableCell padding="checkbox">
        <PersonIcon color="primary" />
      </TableCell>
      <TableCell colSpan={fieldsCount}>
        <Typography variant="subtitle1" sx={(theme) => ({ color: theme.palette.primary.main })}>
          {title}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const HeaderRow = ({ fields, showNetIrrCurve }: RowProps) => (
  <TableRow key="bhr">
    <TableCell key="ico" padding="checkbox" />

    {fields.map((field) => {
      const config = field.config;
      if (config?.hidden) {
        return <TableCell width={0} key={field.guid} />;
      }
      return (
        <TableCell align={config?.align || "left"} sx={{ whiteSpace: "nowrap" }} key={field.guid}>
          {config?.title || field.name}
        </TableCell>
      );
    })}

    {showNetIrrCurve && (
      <TableCell sx={{ whiteSpace: "nowrap" }} key="irr">
        Net IRR Chart
      </TableCell>
    )}
  </TableRow>
);

const TotalRow = ({ fields, totals, currencyCode, showNetIrrCurve }: TotalRowProps) => (
  <TableRow hover key="balance_tr">
    <TableCell key="icotr" padding="checkbox" />

    {fields.map((field) => {
      const config = field.config;
      const value = totals[field.guid];
      const formattedValue = value && config?.format ? config.format(value, currencyCode) : value || "";
      return (
        <TableCell align={config?.align || "left"} key={field.guid}>
          <Typography sx={{ fontWeight: "bold" }}>{formattedValue}</Typography>
        </TableCell>
      );
    })}

    {showNetIrrCurve && <TableCell key="irr"></TableCell>}
  </TableRow>
);

const EmptyRow = ({ showNoDataMsg, colSpan }: { showNoDataMsg?: boolean; colSpan?: number }) => {
  const locale = useLocalization().dashboard.balance;
  return (
    <TableRow
      key="emr"
      sx={(theme) => ({
        height: theme.spacing(4),
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.divider,
      })}
    >
      {showNoDataMsg && (
        <TableCell align="center" colSpan={colSpan || 0}>
          <Typography color="text.secondary">{locale.no_data}</Typography>
        </TableCell>
      )}
    </TableRow>
  );
};

export default BalanceTable;
