import React, { PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { defined } from "../../shared/utilities/typeHelper";
import {
  allInvestorRelationPortalFeatures,
  ClientPermissionSet,
  InvestorRelationPortalFeatures,
  PortalSettings,
} from "../store/state/user/types";

const ClientContext = React.createContext<Client | undefined>(undefined);

interface Client {
  clientCode: string;
  clientTitle: string;
  contactName: string;
  contactEmail: string;
  portalSettings: PortalSettings | undefined;
  isFeatureEnabled: (feature: InvestorRelationPortalFeatures) => boolean;
  isAnyFeatureEnabled: (features: InvestorRelationPortalFeatures[]) => boolean;
  impersonationFundName: string;
}

interface Props {
  clientPermissions: ClientPermissionSet;
  impersonationFundName: string;
  isImpersonationMode: boolean;
}

const getEnabledFeatures = (features: string | undefined) =>
  features === undefined
    ? new Set<InvestorRelationPortalFeatures>()
    : new Set<InvestorRelationPortalFeatures>(
        features
          .split(",")
          .map((f) => f.trim())
          .filter((f) =>
            allInvestorRelationPortalFeatures.includes(f as InvestorRelationPortalFeatures)
          ) as InvestorRelationPortalFeatures[]
      );

export const ClientContextProvider = ({
  clientPermissions,
  impersonationFundName,
  isImpersonationMode,
  children,
}: PropsWithChildren<Props>) => {
  const hasAccessByPermissions = useCallback(
    (feature: InvestorRelationPortalFeatures) => {
      switch (feature) {
        case "Inbox": {
          return clientPermissions.messageCategoryIds.length > 0;
        }

        case "Dashboards": {
          return clientPermissions.financialPermissions.some((fp) => fp.investor.length > 0);
        }

        case "PortfolioCompanies": {
          return clientPermissions.investorsPermissions.some((ip) => ip.fundInvestors.length > 0);
        }

        case "Fundraising": {
          return clientPermissions.fundraisings.length > 0;
        }

        case "DataCollection": {
          return clientPermissions.entityPermissions.length > 0;
        }

        default: {
          return true;
        }
      }
    },
    [
      clientPermissions.entityPermissions.length,
      clientPermissions.financialPermissions,
      clientPermissions.fundraisings.length,
      clientPermissions.investorsPermissions,
      clientPermissions.messageCategoryIds.length,
    ]
  );

  const enabledLiveFeatures = useMemo(
    () => getEnabledFeatures(clientPermissions.portalSettings?.enabledFeatures),
    [clientPermissions.portalSettings?.enabledFeatures]
  );

  const enabledPreviewFeatures = useMemo(
    () => getEnabledFeatures(clientPermissions.portalSettings?.enabledPreviewOnlyFeatures),
    [clientPermissions.portalSettings?.enabledPreviewOnlyFeatures]
  );

  const isFeatureEnabled = useCallback(
    (feature: InvestorRelationPortalFeatures) => {
      if (!hasAccessByPermissions(feature)) {
        return false;
      }

      if (feature === "DataCollection") {
        return clientPermissions.portfolioMonitoringApplication?.dataCollection?.enabled === true;
      }

      return enabledLiveFeatures.has(feature) || (isImpersonationMode && enabledPreviewFeatures.has(feature));
    },
    [
      clientPermissions.portfolioMonitoringApplication?.dataCollection?.enabled,
      enabledLiveFeatures,
      enabledPreviewFeatures,
      hasAccessByPermissions,
      isImpersonationMode,
    ]
  );

  const isAnyFeatureEnabled = useCallback(
    (features: InvestorRelationPortalFeatures[]) => features.some((feature) => isFeatureEnabled(feature)),
    [isFeatureEnabled]
  );

  return (
    <ClientContext.Provider
      value={{
        clientCode: clientPermissions.clientCode,
        clientTitle: clientPermissions.clientTitle,
        contactName: clientPermissions.contactName,
        contactEmail: clientPermissions.contactEmail,
        impersonationFundName,
        portalSettings: clientPermissions.portalSettings,
        isFeatureEnabled,
        isAnyFeatureEnabled,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () =>
  defined(useContext(ClientContext), "Attempt to use ClientContext without provider");
