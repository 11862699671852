import React, { PropsWithChildren } from "react";
import { AnyObject } from "../../../shared/types";
import { useClientContext } from "../../contexts/ClientContext";
import { InvestorRelationPortalFeatures } from "../../store/state/user/types";

interface Props {
  required?: InvestorRelationPortalFeatures;
  requiredAbsence?: InvestorRelationPortalFeatures;
}

const FeatureBoundary = ({ required, requiredAbsence, children, ...externalProps }: PropsWithChildren<Props>) => {
  const { isFeatureEnabled } = useClientContext();
  const renderContent =
    (required === undefined || isFeatureEnabled(required)) &&
    (requiredAbsence === undefined || !isFeatureEnabled(requiredAbsence));

  if (!renderContent) {
    return null;
  }

  if (Object.keys(externalProps).length === 0) {
    return <>{children}</>;
  }

  return React.Children.map(children, (child) =>
    React.isValidElement(child) ? React.cloneElement(child, { ...externalProps, ...(child.props as AnyObject) }) : child
  );
};

export default FeatureBoundary;
