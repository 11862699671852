import { defined } from "../../../shared/utilities/typeHelper";
import { uuidValidator } from "../../../shared/utilities/validators";
import { InboxCriteria } from "../../contexts/InboxStateContext";
import { CategoriesFilterConfiguration, isSystemTag } from "../../store/state/messages/types";

export const getInboxStateFromTag = (tag: string | undefined, categories: CategoriesFilterConfiguration[]) => {
  if (!tag) {
    return;
  }
  let parentCaption: string | undefined;
  let caption: string;
  const criteria: Partial<InboxCriteria> = {};
  if (tag.indexOf("/") !== -1) {
    const tags = tag.split("/");
    parentCaption = defined(tags[0]);
    caption = defined(tags[1]);
    criteria.investors = [parentCaption];
    criteria.funds = [caption];
    criteria.entities = [{ caption }];
  } else {
    parentCaption = undefined;
    caption = tag;
    if (isSystemTag(caption)) {
      criteria.investors = [];
      criteria.categories = [];
    } else if (categories.findIndex((c) => c.value === caption) !== -1) {
      criteria.investors = [];
      criteria.categories = categories.filter((c) => c.value === caption).map((c) => c.key);
    } else if (uuidValidator(caption).isValid) {
      criteria.investors = [];
      criteria.categories = [caption];
    } else {
      criteria.investors = [caption];
      criteria.categories = [];
    }
    criteria.funds = [];
  }
  return criteria;
};

export const generateInboxPath = (state: InboxCriteria, messageId?: string, attachmentId?: string) => {
  const investors = state.investors || [];
  const funds = state.funds || [];
  const categories = state.categories || [];

  const defaultPath = () => {
    return state.isImportant ? "Important" : "Inbox";
  };

  const applyOptions = (path: string) => {
    if (messageId) {
      return attachmentId ? `${path}/message/${messageId}?attachment=${attachmentId}` : `${path}/message/${messageId}`;
    }
    return `${path}/p${state.page}`;
  };

  if (investors.length > 1 || funds.length > 1 || categories.length > 1) {
    return applyOptions(defaultPath());
  }
  if (investors.length === 1 && funds.length === 0 && categories.length === 0) {
    return applyOptions(state.isImportant ? "Important" : encodeURIComponent(investors[0] || ""));
  }
  if (investors.length === 1 && funds.length === 1 && categories.length === 0) {
    return applyOptions(state.isImportant ? "Important" : encodeURIComponent(`${investors[0]}/${funds[0]}`));
  }
  if (investors.length === 1 && funds.length === 1 && categories.length === 1) {
    return applyOptions(defaultPath());
  }
  if (investors.length === 0 && funds.length === 0 && categories.length === 1) {
    return `${categories[0]}`;
  }
  return applyOptions(defaultPath());
};

export const isFilterApplied = (state: InboxCriteria) => {
  return (
    !!state.from ||
    !!state.to ||
    (state.investors && state.investors.length > 0) ||
    (state.funds && state.funds.length > 0) ||
    (state.entities && state.entities.length > 0) ||
    (state.categories && state.categories.length > 0) ||
    !!state?.descriptiveDate
  );
};
