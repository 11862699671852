import { Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { logError } from "../../../../shared/logging";
import { api } from "../../../api/client";
import { loadInboxStructure } from "../inboxStructure/thunks";
import { MessageActions } from "./actions";
import { MessagesPayload } from "./reducer";
import { FilterConfigurationLoadingState, MessageLoadingState } from "./types";

type MessagesDispatch = Dispatch<PayloadAction<MessagesPayload>>;

const loadInboxStructureAsync = loadInboxStructure();

export function markAsImportant(messageIds: string[], value: boolean) {
  return async (dispatch: MessagesDispatch) => {
    dispatch(MessageActions.UpdateLoading({ markAsImportantUpdating: true } as MessageLoadingState));
    try {
      const response = await api.messages.markManyAsImportant(messageIds, value);
      if (response?.success && response.data.updates) {
        response.data.updates.forEach((resp) => {
          dispatch(MessageActions.MarkAsImportant(resp.messageId, resp.markAsImportant));
        });
        await loadInboxStructureAsync(dispatch);
      } else if (response?.error) {
        logError(response.error.message, "markAsImportant");
      }
    } catch (error) {
      logError(error, "markAsImportant");
    } finally {
      dispatch(MessageActions.UpdateLoading({ markAsImportantUpdating: false } as MessageLoadingState));
    }
  };
}

export function loadConfiguration() {
  return async (dispatch: MessagesDispatch) => {
    dispatch(
      MessageActions.UpdateLoading({
        filterConfiguration: FilterConfigurationLoadingState.Loading,
      } as MessageLoadingState)
    );
    let loadingState: FilterConfigurationLoadingState = FilterConfigurationLoadingState.None;
    try {
      const filterConfigResponse = await api.messages.loadFilterConfiguration();
      if (filterConfigResponse?.success) {
        dispatch(
          MessageActions.UpdateState({
            filterConfiguration: filterConfigResponse.data,
          })
        );
        loadingState = FilterConfigurationLoadingState.Loaded;
      } else {
        if (filterConfigResponse?.error) {
          logError(filterConfigResponse.error?.message, "loadFilterConfiguration");
          loadingState = FilterConfigurationLoadingState.Error;
        }
      }
    } catch (error) {
      logError(error, "loadFilterConfiguration");
      loadingState = FilterConfigurationLoadingState.Error;
    } finally {
      dispatch(MessageActions.UpdateLoading({ filterConfiguration: loadingState } as MessageLoadingState));
    }
  };
}
