import { Message, MessageLoadingState, MessagesState } from "./types";

export enum MessageActionsEnum {
  UpdateState = "messages/update-state",
  MessagesLoaded = "messages/messages-loaded",
  MessagesPreLoaded = "messages/messages-preloaded",
  MessageDetailsLoaded = "messages/message-details-loaded",
  MarkAsRead = "messages/mark-as-read",
  MarkAsImportant = "messages/mark-as-important",
  UpdateLoading = "messages/update-loading",
}

export const MessageActions = {
  UpdateState: (state: Partial<MessagesState>) => ({ type: MessageActionsEnum.UpdateState, payload: state }),
  MessagesLoaded: (state: Partial<MessagesState>) => ({ type: MessageActionsEnum.MessagesLoaded, payload: state }),
  MessagesPreLoaded: (state: Partial<MessagesState>) => ({
    type: MessageActionsEnum.MessagesPreLoaded,
    payload: state,
  }),
  MessageDetailsLoaded: (message: Message) => ({ type: MessageActionsEnum.MessageDetailsLoaded, payload: message }),
  MarkAsRead: (id: string) => ({ type: MessageActionsEnum.MarkAsRead, payload: id }),
  MarkAsImportant: (id: string, isImportant: boolean) => ({
    type: MessageActionsEnum.MarkAsImportant,
    payload: { id, isImportant },
  }),
  UpdateLoading: (loading: MessageLoadingState) => ({ type: MessageActionsEnum.UpdateLoading, payload: loading }),
};
