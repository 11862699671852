import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { Box, Button, Stack } from "@mui/material";
import React, { useMemo } from "react";
import DateRangeSelect from "../../../shared/components/DateRangeSelect";
import RecordCounter from "../../../shared/components/filters/RecordCounter";
import SearchField from "../../../shared/components/inputs/SearchField";
import MultiSelectFilter from "../../../shared/components/MultiSelectFilter";
import { distinctBy, stringComparerBy } from "../../../shared/utilities/arrayHelper";
import {
  DescriptiveDateRange,
  lastYear,
  thisMonth,
  thisQuarter,
  thisYear,
} from "../../../shared/utilities/dateFilterHelper";
import { DataCollectionSubmissionInfo } from "../../api/types/dataCollectionTypes";
import { DataSubmissionsFilterState } from "./dataSubmissionsFilterState";

interface Props {
  allRows: DataCollectionSubmissionInfo[];
  filteredRowsCount: number;
  isLoading: boolean;
  filterState: DataSubmissionsFilterState;
  setFilterState: React.Dispatch<React.SetStateAction<DataSubmissionsFilterState>>;
}

const dateRangeOptions = [thisMonth, thisQuarter, thisYear, lastYear];

const DataSubmissionsFilterPanel = ({ allRows, filteredRowsCount, isLoading, filterState, setFilterState }: Props) => {
  const requestTemplateOptions = useMemo(
    () =>
      distinctBy(
        allRows.map((ds) => ({ value: ds.requestTemplateName, label: ds.requestTemplateName })),
        (x) => x.label
      ).sort(stringComparerBy((x) => x.label)),
    [allRows]
  );

  const handleSearch = (searchValue: string) => {
    setFilterState((prev) => ({ ...prev, searchValue }));
  };

  const handleTemplateNameChange = (selectedTemplateNames: string[]) => {
    setFilterState((prev) => ({ ...prev, selectedTemplateNames }));
  };

  const handleReportingDateRangeChange = (reportingDateRange: DescriptiveDateRange) => {
    setFilterState((prev) => ({ ...prev, reportingDateRange }));
  };

  const handleClearFilters = () => {
    setFilterState((prev) => ({ ...prev, selectedTemplateNames: [], reportingDateRange: undefined }));
  };

  const showClearFilters =
    !isLoading && (filterState.selectedTemplateNames.length > 0 || filterState.reportingDateRange);

  return (
    <Box display="flex" justifyContent="space-between" pb={2.5}>
      <Stack direction="row" spacing={1} alignItems="center">
        <MultiSelectFilter
          options={requestTemplateOptions}
          selectedValues={filterState.selectedTemplateNames}
          onChange={handleTemplateNameChange}
          disabled={isLoading || requestTemplateOptions.length === 0}
          placeholder="Type"
          StartIcon={DescriptionIcon}
        />
        <DateRangeSelect
          predefinedRanges={dateRangeOptions}
          dateRange={filterState.reportingDateRange}
          onChange={handleReportingDateRangeChange}
          placeholder="Reporting Date"
          disabled={isLoading}
        />
        {showClearFilters && (
          <Button variant="text" onClick={handleClearFilters}>
            Clear all
          </Button>
        )}
        <Box pl={1}>
          <RecordCounter records={filteredRowsCount} totalRecords={allRows.length} hide={isLoading} />
        </Box>
      </Stack>
      <SearchField
        debounceTimeMs={300}
        initialValue={filterState.searchValue}
        onSearch={handleSearch}
        disabled={isLoading}
      />
    </Box>
  );
};

export default DataSubmissionsFilterPanel;
