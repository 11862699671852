import objectHash from "object-hash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { InboxCriteria, InboxUiState } from "../../contexts/InboxStateContext";
import { loadInboxStructure } from "../../store/state/inboxStructure/thunks";
import { CategoriesFilterConfiguration } from "../../store/state/messages/types";
import { AppDispatch } from "../../store/store";
import { buildSearchCriteria, useLoadMessageDetails, useSearchMessages } from "./inboxState.api";
import { generateInboxPath, getInboxStateFromTag } from "./inboxState.utils";

export const useInboxState = (
  categories: CategoriesFilterConfiguration[],
  initialLocation: string | undefined,
  defaultMessageId: string | undefined,
  refresh: boolean | undefined,
  page: number,
  attachmentId: string | undefined
) => {
  const dispatch: AppDispatch = useDispatch();
  const { searchMessage } = useSearchMessages();
  const { loadMessageDetails } = useLoadMessageDetails();
  const [messageId, setMessageId] = useState(defaultMessageId);

  const [criteria, setCriteria] = useState<InboxCriteria>({
    page,
    size: 50,
    query: "",
    preload: false,
    ...getInboxStateFromTag(initialLocation, categories),
  });

  const [ui, setUi] = useState<InboxUiState>({ messagesLoading: false, messageDetailLoading: false });

  useEffect(() => {
    if (refresh) {
      setCriteria({
        page,
        size: 50,
        query: "",
        preload: false,
        ...getInboxStateFromTag(initialLocation, categories),
      });
      setMessageId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, initialLocation]);

  useEffect(() => {
    dispatch(loadInboxStructure(true));
  }, [dispatch]);

  const updateUi = useCallback((newState: Partial<InboxUiState>) => {
    setUi((prev) => ({ ...prev, ...newState }));
  }, []);

  const searchWithCurrentCriteria = useCallback(async () => {
    updateUi({ messagesLoading: true });
    const shouldRequestTotals = !ui.total || !criteria.page;
    const requestBody = buildSearchCriteria(criteria, shouldRequestTotals);
    await searchMessage(requestBody, criteria.preload, updateUi);
    updateUi({ messagesLoading: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectHash(criteria)]);

  useEffect(() => {
    searchWithCurrentCriteria();
  }, [searchWithCurrentCriteria]);

  useEffect(() => {
    if (!messageId) {
      return;
    }
    updateUi({ messageDetailLoading: true, messageDetailLoadingError: false });
    const fetchData = async () => await loadMessageDetails(messageId);
    fetchData()
      .then(() => updateUi({ messageDetailLoading: false }))
      .catch(() => updateUi({ messageDetailLoading: false, messageDetailLoadingError: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId]);

  const updateCriteria = useCallback((newState: Partial<InboxCriteria>) => {
    setMessageId("");
    setCriteria((currentState) => ({
      ...currentState,
      ...newState,
      preload: false,
    }));
  }, []);

  const updateCriteriaPreload = useCallback((newState: Partial<InboxCriteria>) => {
    setMessageId("");
    setCriteria((currentState) => ({
      ...currentState,
      ...newState,
    }));
  }, []);

  const onSetMessageId = useCallback(
    (newId: string) => {
      if (newId === messageId) {
        return;
      }
      setMessageId(newId);
    },
    [messageId, setMessageId]
  );

  const path = generateInboxPath(criteria, messageId, attachmentId);

  return {
    path,
    criteria,
    ui,
    messageId,
    updateCriteria,
    updateCriteriaPreload,
    updateUi,
    setMessageId: onSetMessageId,
    searchWithCurrentCriteria,
  };
};
