import CircleIcon from "@mui/icons-material/Circle";
import { MessagePublishingStatus } from "../../../store/state/messages/types";

interface Props {
  publishingStatus: MessagePublishingStatus;
}

const MessagePublishStatusIcon = ({ publishingStatus }: Props) => {
  return (
    <CircleIcon
      sx={(theme) => ({
        color: `${publishingStatus === "Live" ? theme.palette.success.main : theme.palette.info.main}`,
        mx: theme.spacing(),
        [theme.breakpoints.down("sm")]: {
          mx: 0,
        },
        fontSize: "0.5rem",
      })}
    />
  );
};

export default MessagePublishStatusIcon;
