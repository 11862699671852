import { Avatar, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import EntriliaLogo from "../../../../shared/images/greylogo.png";
import { ReportCell, ReportColumn, TabularReportRow } from "../../../api/types/portfolioTypes";

export interface TabularReportRowModel {
  id: string;
  cells: Record<string, ReportCell>;
}

export const getRows = (rows: TabularReportRow[]): TabularReportRowModel[] =>
  rows.map((row, index) => ({
    id: `row_${index}`,
    cells: row,
  }));

const CellWithIcon = ({ cell }: { cell: ReportCell }) => {
  return (
    <>
      <Avatar
        src={cell.startIcon || EntriliaLogo}
        variant="rounded"
        alt="logo"
        slotProps={{ img: { sx: { objectFit: "contain" } } }}
        sx={{ width: 24, height: 24 }}
      />
      <Typography ml={1}>{cell.formattedValue}</Typography>
    </>
  );
};

const getColumnDefinition = (column: ReportColumn): GridColDef<TabularReportRowModel> => {
  const alignRight =
    column.valueType === "Integer" || column.valueType === "Decimal" || column.valueType === "DateTime";

  return {
    field: column.id,
    headerName: column.title,
    flex: 1,
    minWidth: column.hasStartIcon ? 184 : 160,
    align: alignRight ? "right" : "left",
    headerAlign: alignRight ? "right" : "left",
    renderCell: ({ row }) => {
      const cell = row.cells[column.id];
      if (cell === undefined) {
        return null;
      }

      if (column.hasStartIcon) {
        return <CellWithIcon cell={cell} />;
      }

      return cell.formattedValue;
    },
  };
};

export const getColumnDefinitions = (columns: ReportColumn[]): GridColDef<TabularReportRowModel>[] =>
  columns.map(getColumnDefinition);
