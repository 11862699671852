import { createAction, createReducer } from "@reduxjs/toolkit";
import { MessageActionsEnum } from "./actions";
import { FilterConfigurationLoadingState, Message, MessageLoadingState, MessagesState } from "./types";

const initialState: MessagesState = {
  messages: [],
  loading: { markAsImportantUpdating: false, filterConfiguration: FilterConfigurationLoadingState.None },
};

interface IMessageMark {
  id: string;
  isImportant: boolean;
}

export type MessagesPayload =
  | Partial<MessagesState>
  | Message
  | IMessageMark
  | MessageLoadingState
  | string
  | undefined;

const updateStateAction = createAction<Partial<MessagesState>>(MessageActionsEnum.UpdateState);
const markAsImportantAction = createAction<IMessageMark>(MessageActionsEnum.MarkAsImportant);
const messagesLoadedAction = createAction<MessagesState | undefined>(MessageActionsEnum.MessagesLoaded);
const messagesPreLoadedAction = createAction<MessagesState | undefined>(MessageActionsEnum.MessagesPreLoaded);
const messageDetailsLoadedAction = createAction<Message>(MessageActionsEnum.MessageDetailsLoaded);
const markAsReadAction = createAction<string>(MessageActionsEnum.MarkAsRead);
const updateLoadingAction = createAction<MessageLoadingState>(MessageActionsEnum.UpdateLoading);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateStateAction, (state, action) => {
      return { ...state, ...action.payload };
    })
    .addCase(markAsImportantAction, (state, action) => {
      const { id, isImportant } = action.payload;
      const message = state.messages.find((msg) => msg.id === id);
      if (message !== undefined) {
        message.isImportant = isImportant;
      }
      if (state.currentMessage && state.currentMessage.id === id) {
        state.currentMessage.isImportant = isImportant;
      }
      return state;
    })
    .addCase(messagesLoadedAction, (state, action) => {
      if (action.payload) {
        state.messages = action.payload.messages;
      }
      return state;
    })
    .addCase(messagesPreLoadedAction, (state, action) => {
      if (action.payload) {
        state.messages = state.messages.concat(action.payload.messages);
      }
      return state;
    })
    .addCase(messageDetailsLoadedAction, (state, action) => {
      state.currentMessage = action.payload;
      return state;
    })
    .addCase(markAsReadAction, (state, action) => {
      const messageId = action.payload;
      const message = state.messages.find((msg) => msg.id === messageId);
      if (message !== undefined) {
        message.isRead = true;
      }
      if (state.currentMessage?.id === messageId) {
        state.currentMessage.isRead = true;
      }
      return state;
    })
    .addCase(updateLoadingAction, (state, action) => {
      state.loading = action.payload;
      return state;
    });
});
